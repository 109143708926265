import { ChamberBellSubTypeId } from 'proceduralsystem-cbs-common-components';
import { IOirDialogData } from 'proceduralsystem-clientcomponents';

export const MAX_QUORUM_DURATION = 99;
export const MIN_QUORUM_DURATION = 0.5;
export const QUORUM_DEFAULT_DURATION = 3;

export const QUORUM_TYPE_OPTIONS = [
  {
    title: 'CALL_BELL_DIALOG.CHOICE.OPTION_1',
    value: ChamberBellSubTypeId.QuorumBusinessItem
  },
  {
    title: 'CALL_BELL_DIALOG.CHOICE.OPTION_2',
    value: ChamberBellSubTypeId.QuorumSchedule
  }
];

export interface ICallQuorumDialogData extends IOirDialogData {
  context?: {
    businessItemName?: string;
    minDuration?: number;
  };
}

export interface QuorumConfirmDialogData {
  type: ChamberBellSubTypeId;
  duration?: string;
}
