import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  IChamberUser,
  UserRole,
  BadgeUtils
} from 'proceduralsystem-cbs-common-components';
import { InAppState } from '@app/app.types';
import { selectChamberUser } from '@app/store/reducers/live-sharing.reducer';

@Component({
  selector: 'oir-chamber-user-info',
  templateUrl: './chamber-user-info.component.html',
  styleUrls: ['./chamber-user-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChamberUserInfoComponent {
  @Output() clockOut: EventEmitter<void> = new EventEmitter();
  @Output() clockIn: EventEmitter<void> = new EventEmitter();
  @Output() close: EventEmitter<void> = new EventEmitter();

  chamberUser$ = this.store.select(selectChamberUser);
  expanded = false;
  BadgeUtils = BadgeUtils;
  UserRole = UserRole;

  constructor(private store: Store<InAppState>) {}

  processClockOut(user: IChamberUser): void {
    if (user.role === UserRole.ChamberClerk) {
      this.clockOut.emit();
    }
  }

  processClockIn(): void {
    this.clockIn.emit();
  }
}
