import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { OirHttpService } from 'proceduralsystem-clientcomponents';
import { InAppState } from '@app/app.types';
import { Store } from '@ngrx/store';
import * as LiveSharingAction from '@app/store/actions/live-sharing.actions';
import { selectChamberUser } from '@app/store/reducers/live-sharing.reducer';

@Injectable()
export class LiveSharingEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly oirHttp: OirHttpService,
    private store: Store<InAppState>
  ) {}

  setLiveView$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LiveSharingAction.postLiveView),
        concatLatestFrom(() => this.store.select(selectChamberUser)),
        switchMap(([action, user]) => {
          if (user) {
            return this.oirHttp.post<boolean>({
              path: '/api/liveSharing/setLiveView',
              body: {
                page: action.page,
                data: action.data,
                user
              }
            });
          }

          return of(null);
        })
      ),
    { dispatch: false }
  );
}
