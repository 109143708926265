<div *ngIf="applicationSelectExpanded" class="overlay"></div>
<div class="main-container" [class.collapsed]="collapsed" *ngIf="navigationService.getNavigationModel() | async; let model;" role="navigation">
  <ul class="desktop ta_n_navigationList">
    <li class="top-container ta_n_oireachtas" [ngStyle]="{'background-color': getEnvironmentColour()}">
      <div class="logo" [class.slow-show]="!collapsed" role="link" tabindex="0" (click)="navigate(model.homeUrl)"></div>
    </li>
    <li class="node active-member" (click)="toggleApplicationSelect()" [class.expanded]="applicationSelectExpanded">
      <div class="title-wrap label-section" [class.hidden-label]="collapsed">
        <div class="title bright-label" *ngIf="!collapsed">{{ 'APPLICATION.MODULE.TITLE' | translate }}</div>
        <div class="title secondaryTitle" *ngIf="!collapsed">{{ 'APPLICATION.MODULE.SWITCHAPP' | translate }}</div>
      </div>
      <div class="chevron" [class.expanded]="applicationSelectExpanded">
        <img alt="" src="./assets/img/arrow-toggle.svg">
      </div>
    </li>

    <div *ngIf="applicationSelectExpanded" class="application-select">
        <li class="node switch-action" *ngFor="let otherApp of otherApps$ | async"
            (click)="switchApplication(otherApp)">
          <div class="title-wrap">
            <div class="title">{{ otherApp.title }}</div>
          </div>
        </li>
    </div>

    <ng-container>
      <ng-container *ngFor="let node of model.tree">
        <li tabindex="0" class="node ta_n_{{node.title}} menu-item"
            [class.hidden]="applicationSelectExpanded"
            (click)="clearSessionTabs()"
            [routerLink]="node.path" [class.active]="node.selected">
          <div *ngIf="node.icon" class="icon" aria-hidden="true">
            <mat-icon aria-hidden="true">{{ node.icon }}</mat-icon>
          </div>
          <div class="label-section" aria-hidden="true" [class.hidden-label]="collapsed">
            <div class="title-wrap">
              <div class="title" *ngIf="!collapsed">{{ node.title | translate }}</div>
            </div>
          </div>
        </li>
      </ng-container>
    </ng-container>
  </ul>
</div>
