<div class="modal-dialog call-division-bell-dialog ta_s_callDivisionDialog">
	<div class="modal-header">
		<h2 mat-dialog-title class="ta_s_callDivisionDialog_title">{{ data.title | translate }}</h2>
		<mat-icon [mat-dialog-close]="null" class="mat-close-icon ta_close" aria-label="close icon">close</mat-icon>
  </div>
  
	<mat-dialog-content class="modal-body">
		<div class="dialog-message ta_s_callDivisionDialog_message">
			<label [htmlFor]="bellForm">{{ data.message | translate }}</label>
    </div>

    <form [formGroup]="divisionForm" #bellForm>

      <mat-radio-group class="ta_s_callDivision_type" formControlName="type">
        <ng-container *ngFor="let option of OPTIONS; let i = index">
          <div *ngIf="!divisionForm.get('type').value && (i === 1)" class="ta_s_callDivisionDialog_optionSeparator option-separator">
            <mat-divider></mat-divider><span class="separator-or">{{ "COMMON.OR" | translate }}</span><mat-divider></mat-divider>
          </div>
          <mat-radio-button
            *ngIf="!divisionForm.get('type').value || (divisionForm.get('type').value === option.value)"
            [disabled]="i === 0 
              ? !availableSubtypes.includes(ChamberBellSubTypeId.DivisionBusinessItem)
              : !availableSubtypes.includes(ChamberBellSubTypeId.DivisionSchedule) && !availableSubtypes.includes(ChamberBellSubTypeId.DivisionGuillotine)"
            class="ta_s_callDivisionDialog_type_option_{{i}} radio-button-mat subtitle-secondary" [value]="option.value">
            <p>{{ option.title | translate }}</p>
          </mat-radio-button>
        </ng-container>
      </mat-radio-group>

      <div class="input-section" *ngIf="divisionForm.get('type').value">
        <oir-singleselect
          class="ta_s_callDivisionDialog_bellType"
          [showHint]="false"
          [errorMessage]="'SINGLESELECT.NO_SELECTION' | translate"
          [data]="BELL_OPTIONS"
          label="CALL_DIVISION_DIALOG.BELL_TYPE.LABEL"
          [control]="divisionForm.get('bellType')"
          [errorMessage]="'SINGLESELECT.NO_SELECTION' | translate">
        </oir-singleselect>

        <ng-container *ngIf="divisionForm.get('type').value === OPTIONS[0].value">
          <ng-container  *ngTemplateOutlet="stageInput"></ng-container>
        </ng-container>
        
        <mat-form-field
          *ngIf="divisionForm.get('type').value === OPTIONS[1].value"
          appearance="fill"
          class="input-field ta_s_callDivisionDialog_duration">
          <mat-label>{{ 'CALL_DIVISION_DIALOG.DURATION.LABEL' | translate }}</mat-label>
          <input
            matInput
            formControlName="duration"
            autoComplete="off"
            maxlength="2">
          <mat-hint align="end">{{ 'CALL_DIVISION_DIALOG.DURATION.HINT' | translate }}</mat-hint>
          <mat-error>
            <mat-hint>{{ 'CALL_DIVISION_DIALOG.DURATION.HINT' | translate }}</mat-hint>
            <br />
            {{ 'CALL_DIVISION_DIALOG.DURATION.ERROR' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="divisionForm.get('type').value === OPTIONS[1].value" class="associated-division-section">
        <div class="toggle-section">
          <mat-slide-toggle class="switch-mat ta_s_callDivisionDialog_toggle" formControlName="associatedDivision">
            {{ "CALL_DIVISION_DIALOG.ASSOCIATE_DIVISION.LABEL" | translate}}
          </mat-slide-toggle>
          <p class="ta_s_callDivisionDialog_toggleInfo associated-division-info" *ngIf="!!divisionForm.get('associatedDivision').value && data?.context?.businessItemName">
            {{ data.context.businessItemName }}
          </p>
        </div>
        <div class="stage-section" *ngIf="!!divisionForm.get('associatedDivision').value">
          <ng-container *ngTemplateOutlet="stageInput"></ng-container>
        </div>
      </div>
      
    </form>
  </mat-dialog-content>

  <div class="modal-footer">
    <div class="page-navigation">
      <button
        *ngIf="!!divisionForm.get('type').value"
        (click)="resetForm()"
        class="icon-button page-back ta_s_callDivisionDialog_pageBackBttn">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div class="page-dots ta_s_callDivisionDialog_pageNavigation">
        <div class="ta_s_callDivisionDialog_page_1" [class.active]="!divisionForm.get('type').value"></div>
        <div class="ta_s_callDivisionDialog_page_2" [class.active]="divisionForm.get('type').value"></div>
      </div>
    </div>

    <mat-dialog-actions class="dialog-buttons">
      <button
        mat-button
        class="btn-basic ta_cancel"
        color="primary"
        [mat-dialog-close]="null"
        type="button">{{ data.cancelButton.name | translate | uppercase }}</button>
      <button
        mat-button
        class="btn-basic ta_confirm"
        color="primary"
        [disabled]="divisionForm.invalid"
        [mat-dialog-close]="divisionForm.getRawValue()"
        type="button">{{ data.confirmButton.name | translate | uppercase}}</button>
    </mat-dialog-actions>
  </div>
</div>

<ng-template #stageInput>
  <oir-singleselect
    class="ta_s_callDivisionDialog_stageType"
    [showHint]="false"
    label="CALL_DIVISION_DIALOG.STAGE_TYPE.LABEL"
    [control]="divisionForm.get('stage')"
    [data]="stageList">
  </oir-singleselect>
</ng-template>