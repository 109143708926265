import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import {
  ConfigurationService,
  OirAuthService,
  ToastNotificationsService
} from 'proceduralsystem-clientcomponents';
import {
  AppConfig,
  businessItemStateChanged,
  ConnectionHubService,
  HubEventTypeId,
  HubStateChanged,
  HubToastMessage,
  SystemHubMethod,
  ToastEventTypeId,
  ToastMessageHubMethod,
  UserRole,
  ProgressBarService
} from 'proceduralsystem-cbs-common-components';
import { InAppState, ISpeakerParams } from '@app/app.types';
import { ChatService } from '@shared/components/chat/chat.service';
import {
  setChatStatus,
  setMessages,
  setNewMessage,
  setVideoUrl
} from '@app/store/actions/chat.actions';
import { ChatStatus } from '@shared/components/chat/chat.types';
import {
  initLiveView,
  setConnectionId,
  setLiveView,
  userClockedIn,
  userClockedOut
} from '@app/store/actions/live-sharing.actions';
import { TranslateService } from '@ngx-translate/core';
import { InCbsUserService } from '@app/services/in-cbs-user.service';
import { selectChamberUser } from '@app/store/reducers/live-sharing.reducer';
import { ActiveUsersService } from '@shared/components/active-users/active-users.service';
import {
  chairDueToStart,
  clerkDueToStart,
  recalledNotes,
  recalledSpeakersList,
  speakerListSlotStateChanged,
  speakersListStateChanged,
  clockSlotStateChanged,
  clockSlotSpeakerStateChanged,
  cliffNoteStateChanged
} from '@app/store/actions/in-chamber.actions';

export enum ChatHubMethod {
  StateChange = 'chat_state_changed',
  NewMessage = 'chat_new_message'
}

export enum LiveSharingHubMethod {
  LiveViewChange = 'live_view_change'
}

export enum LiveBarHubMethod {
  ClerkClockedIn = 'chamber_clerk_clocked_in',
  ClerkClockedOut = 'chamber_clerk_clocked_out'
}

@Injectable({ providedIn: 'root' })
export class InConnectionHubService extends ConnectionHubService {
  constructor(
    protected http: HttpClient,
    protected config: ConfigurationService<AppConfig>,
    protected authService: OirAuthService,
    protected translate: TranslateService,
    protected toastService: ToastNotificationsService,
    protected store: Store<InAppState>,
    protected chat: ChatService,
    protected activeUsersService: ActiveUsersService,
    private readonly inCbsUserService: InCbsUserService,
    private readonly progressBarService: ProgressBarService
  ) {
    super(http, config, authService, translate, toastService, store);
  }

  override addListeners(): void {
    super.addListeners();

    if (this.connection) {
      this.store.dispatch(
        setConnectionId({ connectionId: this.getConnectionId() })
      );

      // SL, Clock tab change
      this.connection.on(
        SystemHubMethod.StateChanged,
        (data: HubStateChanged) => {
          switch (data.EventTypeId) {
            case HubEventTypeId.SpeakingListSlotSpeaker:
              this.store.dispatch(
                speakerListSlotStateChanged({ params: this.getParams(data) })
              );
              break;
            case HubEventTypeId.ClockSlotSpeaker:
              this.store.dispatch(
                clockSlotSpeakerStateChanged({
                  clockParams: this.getParams(data)
                })
              );
              break;
            case HubEventTypeId.ClockTab:
              this.store.dispatch(
                clockSlotStateChanged({
                  changedClockItem: {
                    businessItemId: data.Data.BusinessItemId,
                    status: data.StateId
                  }
                })
              );
              break;
            case HubEventTypeId.SpeakingList:
              this.store.dispatch(
                speakersListStateChanged({
                  changedSLBusinessItem: {
                    businessItemId: data.Data.BusinessItemId,
                    status: data.StateId
                  }
                })
              );
              break;
            case HubEventTypeId.CliffNote:
              this.store.dispatch(
                cliffNoteStateChanged({
                  changedCliffNote: {
                    businessItemId: data.Data.BusinessItemId,
                    actionId: data.ActionId,
                    status: data.StateId
                  }
                })
              );
              break;
          }
        }
      );
      // Chat commands
      this.connection.on(
        ChatHubMethod.StateChange,
        (chatStatus: ChatStatus) => {
          this.store.dispatch(setChatStatus({ chatStatus }));
        }
      );
      this.connection.on(ChatHubMethod.NewMessage, message => {
        this.store.dispatch(
          setNewMessage({
            message: {
              id: message.Id,
              userId: message.UserId,
              userFullName: message.UserFullName,
              userRole: message.UserRole,
              message: message.Message,
              timestamp: message.Timestamp,
              isUrgent: message.IsUrgent,
              isAcknowledged: message.IsAcknowledged
            }
          })
        );
      });
      // Live and Header Bar commands
      this.connection.on(LiveBarHubMethod.ClerkClockedIn, data => {
        if (data.NewEntry) {
          this.store.dispatch(
            userClockedIn({
              chamberUser: {
                userObjectId: data.NewEntry.Id,
                name: data.NewEntry.name,
                role: UserRole.ChamberClerk
              }
            })
          );
          this.progressBarService.setClockedName(data.NewEntry.name);
        }
      });
      this.connection.on(LiveBarHubMethod.ClerkClockedOut, data => {
        if (data.NewEntry) {
          this.store.dispatch(
            userClockedOut({
              chamberUser: data.NewEntry
            })
          );
          this.progressBarService.setClockedName(data.NewEntry.name);
        }
      });
      this.connection.on(LiveSharingHubMethod.LiveViewChange, data => {
        const liveView = {
          user: {
            userObjectId: data.User.UserObjectId,
            name: data.User.Name,
            role: data.User.Role
          },
          page: data.Page,
          data: data.Data
        };
        this.store.dispatch(setLiveView({ liveView }));
      });
      // System commands
      this.connection.on(
        SystemHubMethod.StateChanged,
        (data: HubStateChanged) => {
          switch (data.EventTypeId) {
            case HubEventTypeId.SpeakingListSlot:
            case HubEventTypeId.ClockSlot:
              this.store.dispatch(businessItemStateChanged({ params: data }));
              break;
          }
        }
      );
      // Toast Message commands
      this.connection.on(
        ToastMessageHubMethod.ToastMessage,
        (data: HubToastMessage) => {
          switch (data.ToastEventTypeId) {
            case ToastEventTypeId.ClerkDueToStart:
              this.store.select(selectChamberUser).subscribe(chamberUser => {
                if (chamberUser) {
                  const users = data.Data.Users.filter(
                    u => u.userObjectId !== chamberUser.userObjectId
                  );
                  if (users.length > 0) {
                    this.store.dispatch(
                      clerkDueToStart({
                        clerkDueToStart: users.map(u => u.FullName)
                      })
                    );
                  }
                }
              });
              break;
            case ToastEventTypeId.ChairDueToStart:
              this.store.select(selectChamberUser).subscribe(chamberUser => {
                if (chamberUser) {
                  const users = data.Data.Users.filter(
                    u => u.userObjectId !== chamberUser.userObjectId
                  );
                  if (users.length > 0) {
                    this.store.dispatch(
                      chairDueToStart({
                        chairDueToStart: users.map(u => u.FullName)
                      })
                    );
                  }
                }
              });
              break;
            case ToastEventTypeId.RecalledSpeakersList: {
              // Value is refreshed because store doesn't trigger on same value dispatch
              this.store.dispatch(recalledSpeakersList({ itemId: null }));
              this.store.select(selectChamberUser).subscribe(chamberUser => {
                if (
                  chamberUser &&
                  chamberUser.userObjectId !== data.Data.UserObjectId
                ) {
                  this.store.dispatch(
                    recalledSpeakersList({ itemId: data.Data.ItemId })
                  );
                }
              });
              break;
            }
            case ToastEventTypeId.RecalledNotes: {
              // Value is refreshed because store doesn't trigger on same value dispatch
              this.store.dispatch(recalledNotes({ itemId: null }));
              this.store.select(selectChamberUser).subscribe(chamberUser => {
                if (
                  chamberUser &&
                  chamberUser.userObjectId !== data.Data.UserObjectId
                ) {
                  this.store.dispatch(
                    recalledNotes({ itemId: data.Data.ItemId })
                  );
                }
              });
              break;
            }
            // Clocking commands
            case ToastEventTypeId.ClockOutReminder:
              if (data && data.Data) {
                this.store.select(selectChamberUser).subscribe(chamberUser => {
                  if (
                    chamberUser &&
                    chamberUser.userObjectId ===
                      data.Data.ChamberDaySessionLogUser
                  ) {
                    this.toastService.addNotification({
                      description: this.translate.instant(
                        'CLOCK-OUT.MINUTES_REMINDER_TOAST_MESSAGE',
                        [data.Data.NotifyBeforeMinutes]
                      )
                    });
                  }
                });
              }
              break;
            case ToastEventTypeId.ClockOutSessionExpired:
              if (data) {
                this.store.select(selectChamberUser).subscribe(chamberUser => {
                  if (chamberUser && chamberUser.userObjectId === data.Data) {
                    this.toastService.addNotification({
                      description: 'CLOCK-OUT.SESSION_EXPIRED_TOAST_MESSAGE'
                    });
                    this.inCbsUserService.postClockOut().subscribe();
                  }
                });
              }
              break;
          }
        }
      );
    }

    this.processLiveViewData();
    this.processChatData();
  }

  override removeListeners(): void {
    super.removeListeners();

    if (this.connection) {
      this.connection.off(ChatHubMethod.NewMessage);
      this.connection.off(ChatHubMethod.StateChange);
    }
  }

  private processLiveViewData(): void {
    this.activeUsersService.fetchLiveViewData().subscribe(liveViews => {
      this.store.dispatch(initLiveView({ liveViews }));
    });
  }

  private processChatData(): void {
    this.chat.fetchChatData().subscribe(info => {
      this.store.dispatch(setChatStatus({ chatStatus: info.stateId }));
      this.store.dispatch(setVideoUrl({ videoUrl: info.videoUrl }));
      this.store.dispatch(
        setMessages({ messages: info.messages ? info.messages : [] })
      );
    });
  }

  private getParams(data: HubStateChanged): ISpeakerParams {
    return {
      actionId: data.ActionId,
      businessItemId: data.Data ? data.Data.BusinessItemId : null,
      itemId: data.ItemId,
      stateId: data.StateId
    };
  }
}
