import { LogLevel, Configuration, BrowserCacheLocation, RedirectRequest } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: '', // This is the ONLY mandatory field that you need to supply. SPA application Client Id
    authority: 'https://login.microsoftonline.com/ce71ecf0-0b97-47b2-966c-b4ecc8db23f2', // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: '/', // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
/*      loggerCallback(logLevel: LogLevel, message: string) {
        console.log(message);
      },*/
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false
    }
  }
}

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
const protectedResources = {
  endpoints: [
    "https://commsapidev.azurewebsites.net"
  ],
  scopes: [
    '5c119b35-57d5-4ff9-a37f-4b0708d80e13/.default'
  ]
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
const authRequest: RedirectRequest = {
  scopes: ['919b8665-e880-4543-bd0f-b88674ac39c3/.default']
};
