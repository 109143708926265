import { createAction, props } from '@ngrx/store';
import { ScheduleStatusId } from 'proceduralsystem-cbs-common-components';
import { ChatStatus, IChatMessage } from '@shared/components/chat/chat.types';

const ACTION_NAME = '[CHAT ACTION]';
export const NEW_MESSAGE = `${ACTION_NAME} New Message`;
export const SET_MESSAGES = `${ACTION_NAME} Set Messages`;
export const SET_CHAT_STATUS = `${ACTION_NAME} Set Chat Status`;
export const SET_VIDEO_URL = `${ACTION_NAME} Set Video Url`;
export const SET_SCHEDULE_STATUS_ID = `${ACTION_NAME} Set Schedule Status Id`;

export const setNewMessage = createAction(
  NEW_MESSAGE,
  props<{ message: IChatMessage }>()
);

export const setMessages = createAction(
  SET_MESSAGES,
  props<{ messages: IChatMessage[] }>()
);

export const setVideoUrl = createAction(
  SET_VIDEO_URL,
  props<{ videoUrl: string }>()
);

export const setChatStatus = createAction(
  SET_CHAT_STATUS,
  props<{ chatStatus: ChatStatus }>()
);

export const setScheduleStatusId = createAction(
  SET_SCHEDULE_STATUS_ID,
  props<{ scheduleStatusId: ScheduleStatusId }>()
);

export const setScheduleId = createAction(
  SET_SCHEDULE_STATUS_ID,
  props<{ scheduleId: number }>()
);
