import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  CbsNavigationService,
  ErrorNavigationPage,
  ErrorPageTypeId
} from 'proceduralsystem-cbs-common-components';
import { NavigationService, Node } from 'proceduralsystem-clientcomponents';
import { NavigationPage, PageTitle } from '@app/app.types';
import { ScheduleTab } from '@app/pages/schedule/schedule.types';

@Injectable({ providedIn: 'root' })
export class InCbsNavigationService extends CbsNavigationService {
  constructor(
    readonly navigationService: NavigationService,
    readonly router: Router,
    readonly activatedRoute: ActivatedRoute,
    readonly translate: TranslateService,
    readonly titleService: Title
  ) {
    super(navigationService, router, activatedRoute, translate, titleService);
  }

  clearNavigationToSchedule(): void {
    this.navigationService.select(this.navigationService.currentNode);
    const taskManagerNode = this.navigationService.model.tree.find(
      x => x.title === PageTitle.Schedule
    );
    taskManagerNode.expanded = false;
  }

  navigateToSchedule(tabIndex: ScheduleTab = null): void {
    const queryParams = tabIndex ? { tabIndex } : {};

    this.router.navigate([`${NavigationPage.Schedule}`], { queryParams });
  }

  navigateToBIView(
    id: number,
    tabIndex: number = null,
    subTabIndex: number = null
  ): void {
    const queryParams = tabIndex
      ? subTabIndex
        ? { tabIndex, subTabIndex }
        : { tabIndex }
      : {};
    this.router.navigate([NavigationPage.BusinessItemView, id], {
      queryParams
    });
  }

  navigateToSosView(id: number): void {
    this.router.navigate([NavigationPage.SOSView, id]);
  }

  navigateToReferenceNotes(): void {
    this.router.navigate([`${NavigationPage.ReferenceNotes}`]);
  }

  navigateToDivisionView(scheduleId: number, divisionId: number): void {
    this.router.navigate([
      NavigationPage.Schedule,
      scheduleId,
      NavigationPage.DivisionView,
      divisionId
    ]);
  }

  navigateToErrorPage(
    typeId: ErrorPageTypeId = ErrorPageTypeId.NotFound
  ): void {
    this.router.navigate(
      [
        typeId === ErrorPageTypeId.NoAccess
          ? ErrorNavigationPage.NoAccess
          : ErrorNavigationPage.PageNotFound
      ],
      { skipLocationChange: true }
    );
  }

  getCurrentRoute(): string {
    return this.router.url || '';
  }

  override getNavigationItems(): Node[] {
    const tree: Node[] = [
      {
        title: PageTitle.Schedule,
        path: NavigationPage.Schedule,
        icon: 'event_note'
      },
      {
        title: PageTitle.ReferenceNotes,
        path: NavigationPage.ReferenceNotes,
        icon: 'content_paste_search'
      }
    ];

    return tree;
  }
}
