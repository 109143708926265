import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogPosition } from 'proceduralsystem-clientcomponents';
import { ChatComponent } from '../../chat.component';
import { ChatButtonPosition } from './chat-button.type';
import { Store } from '@ngrx/store';
import { selectChatMessages } from '@app/store/reducers/chat.reducer';
import { IChatMessage } from '../../chat.types';

@Component({
  selector: 'oir-chat-button',
  templateUrl: './chat-button.component.html',
  styleUrls: ['./chat-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatButtonComponent {
  @Input() chatButtonPosition = ChatButtonPosition.right;

  private isInit = false;
  private isChatOpen = false;

  constructor(private matDialog: MatDialog, private store: Store) {
    this.store.select(selectChatMessages).subscribe((v: IChatMessage[] | null) => {
      if (v && !this.isInit) {
        this.isInit = true;
      } else if (this.isInit && !this.isChatOpen) {
        this.openChat();
      }
    });
  }

  openChat() {
    this.isChatOpen = true;

    const dialogRef = this.matDialog.open(ChatComponent, {
      hasBackdrop: true,
      position: DialogPosition.TOP_RIGHT,
      panelClass: 'sidebar-dialog',
      backdropClass: 'sidebar-dialog-overlay-dark',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(() => {
      this.isChatOpen = false;
    });
  }
}
