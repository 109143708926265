import { BellOptionMenuItem } from '@app/shared/components/bell-options-menu/bell-options-menu.types';
import { ChamberBellTimeTypeId } from 'proceduralsystem-cbs-common-components';
import { IOirDialogData } from 'proceduralsystem-clientcomponents';

export const MAX_DIVISION_DURATION = 99;
export const DIVISION_DEFAULT_DURATION = 20;

export enum DivisionBellType {
  Long = 1,
  Manual,
  Short,
  Shorter
}

export enum DIVISION_TYPE_OPTIONS_VALUES {
  VAL_1 = 1,
  VAL_2
}

export const DIVISION_TYPE_OPTIONS = [
  {
    title: 'CALL_BELL_DIALOG.CHOICE.OPTION_1',
    value: DIVISION_TYPE_OPTIONS_VALUES.VAL_1
  },
  {
    title: 'CALL_BELL_DIALOG.CHOICE.OPTION_2',
    value: DIVISION_TYPE_OPTIONS_VALUES.VAL_2
  }
];

export const DIVISION_BELL_OPTIONS = [
  {
    title: 'CALL_DIVISION_DIALOG.BELL_TYPE.OPTION_1',
    value: DivisionBellType.Long
  },
  {
    title: 'CALL_DIVISION_DIALOG.BELL_TYPE.OPTION_2',
    value: DivisionBellType.Manual
  },
  {
    title: 'CALL_DIVISION_DIALOG.BELL_TYPE.OPTION_3',
    value: DivisionBellType.Short
  },
  {
    title: 'CALL_DIVISION_DIALOG.BELL_TYPE.OPTION_4',
    value: DivisionBellType.Shorter
  }
];

export interface ICallDivisionDialogData extends IOirDialogData {
  context?: {
    businessItemName?: string;
    minDuration?: number;
    bells?: BellOptionMenuItem[];
    scheduleId?: number;
  };
}

export interface DivisionConfirmDialogData {
  type: DIVISION_TYPE_OPTIONS_VALUES;
  bellType: { value: ChamberBellTimeTypeId };
  stage: { value?: number };
  duration: string;
  associatedDivision?: boolean;
}
