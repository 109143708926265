import { UserRole } from 'proceduralsystem-cbs-common-components';

export enum ChatStatus {
  NotActive,
  Active,
  Completed
}

export interface IChatMessage {
  id?: number;
  userId?: string;
  userFullName: string;
  userRole?: UserRole;
  message: string;
  timestamp?: string;
  isUrgent?: boolean;
  isAcknowledged?: boolean;
}

export interface IChatResponse {
  videoUrl: string;
  stateId: ChatStatus;
  messages: IChatMessage[];
}
