import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerLocaleData } from '@angular/common';
import localeGa from '@angular/common/locales/ga';
registerLocaleData(localeGa);
import {
  ConfigurationService,
  ProceduralsystemClientcomponentsModule
} from 'proceduralsystem-clientcomponents';
import {
  ProceduralsystemCbsCommonComponentsModule,
  LiveBarEffects,
  AppConfig
} from 'proceduralsystem-cbs-common-components';
import { HeaderBarModule } from '@shared/components/header-bar/header-bar.module';
import { AppComponent } from './app.component';
import { PageComponent } from './page.component';
import { routing } from './app.routes';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { chatFeatureKey, chatReducer } from './store/reducers/chat.reducer';
import {
  liveSharingFeatureKey,
  liveSharingReducer
} from '@app/store/reducers/live-sharing.reducer';
import { LiveSharingEffects } from '@app/store/effects/live-sharing.effects';
import { ChatModule } from '@shared/components/chat/chat.module';
import { ActiveUsersModule } from '@shared/components/active-users/active-users.module';
import { InChamberSideNavigationModule } from '@shared/components/in-chamber-side-navigation/in-chamber-side-navigation.module';
import { InChamberNoAccessPageComponent } from '@shared/components/in-chamber-error-page/in-chamber-no-access-page.component';
import {
  inChamberFeatureKey,
  inChamberReducer
} from '@app/store/reducers/in-chamber.reducer';
import { BellTimerEffects } from './store/effects/bell-timer.effects';
import { QuorumEndingDialogModule } from './shared/dialogs/quorum-ending-dialog/quorum-ending-dialog.module';
import {
  bellTimerReducer,
  bellTimerFeatureKey
} from './store/reducers/bell-timer.reducer';

// AoT requires an exported function for factories
export function HttpLoaderFactory(
  httpHandler: HttpBackend,
  config: ConfigurationService<AppConfig>
): TranslateHttpLoader {
  return new TranslateHttpLoader(
    new HttpClient(httpHandler),
    './assets/i18n/',
    `.json?v=${config.getValue('VersionNumber')}`
  );
}

@NgModule({
  declarations: [AppComponent, PageComponent, InChamberNoAccessPageComponent],
  imports: [
    BrowserModule,
    RouterModule,
    routing,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend, ConfigurationService]
      },
      defaultLanguage: 'en',
      isolate: false
    }),
    StoreModule.forRoot({}),
    StoreModule.forFeature(inChamberFeatureKey, inChamberReducer),
    StoreModule.forFeature(chatFeatureKey, chatReducer),
    StoreModule.forFeature(bellTimerFeatureKey, bellTimerReducer),
    StoreModule.forFeature(liveSharingFeatureKey, liveSharingReducer),
    EffectsModule.forRoot([
      LiveSharingEffects,
      LiveBarEffects,
      BellTimerEffects
    ]),
    // Instrumentation must be imported after importing StoreModule (config is optional)
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true // Pauses recording actions and state changes when the extension window is not open
    }),
    HeaderBarModule,
    ProceduralsystemClientcomponentsModule,
    ProceduralsystemCbsCommonComponentsModule,
    ChatModule,
    ActiveUsersModule,
    InChamberSideNavigationModule,
    MsalModule,
    QuorumEndingDialogModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (config: ConfigurationService<AppConfig>) => () =>
        config.init(),
      deps: [ConfigurationService],
      multi: true
    }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
