import {
  BusinessItemStatusId,
  BusinessItemTypeId,
  ITabView,
  NotesStatusId,
  SpeakingListStatusId,
  ScheduleStatusId,
  ActionButtonStatus,
  AccessOperation,
  ITimeSlot,
  IOngoingBusinessItems,
  ChamberBell,
  ScheduleItem
} from 'proceduralsystem-cbs-common-components';

export enum ScheduleTab {
  Dail,
  ClerkRota,
  ChairRota
}

export const ScheduleTabsData: ITabView<ScheduleTab>[] = [
  {
    title: 'SCHEDULE.TAB.DAIL',
    tabIndex: ScheduleTab.Dail
  },
  {
    title: 'SCHEDULE.TAB.CLERK',
    tabIndex: ScheduleTab.ClerkRota
  },
  {
    title: 'SCHEDULE.TAB.CHAIR',
    tabIndex: ScheduleTab.ChairRota
  }
];

export interface IDailSchedule {
  id: number;
  date: string;
  statusId: ScheduleStatusId;
  updatedAt: string;
  scheduleItems: ScheduleItem[];
  ongoingChamberBell: ChamberBell;
  ongoingBusinessItem: IOngoingBusinessItems;
}

export interface IBusinessItem extends ITimeSlot {
  id: number;
  position: number;
  title: string;
  instanceId?: number;
  instancePosition?: number;
  durationPlanned: number;
  durationActual: number;
  speakingListStatusId: SpeakingListStatusId;
  notesStatusId: NotesStatusId;
  typeId: BusinessItemTypeId;
  workItemTitle: string;
  workItemPosition: number;
  sharedSlot: boolean;
  businessItemStatusId: BusinessItemStatusId;
  elapsed: number;
  completionPercent: number;
  arrangementsOnFront: string[];
  actionButtonStatusId: ActionButtonStatus;
}

export interface IActionButtonData {
  scheduleId: number;
  id: number;
  instanceId: number;
}

// Access
export enum ScheduleComponentAccessOperation {
  ShowSuspendScheduleButton = 15,
  ShowAdjournScheduleButton,
  ShowExtendSuspendScheduleButton,
  ShowResumeScheduleButton,
  ShowRingBellButton
}

export type ScheduleAccessOperation =
  | ScheduleComponentAccessOperation
  | AccessOperation;
