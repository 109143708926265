export const DEFAULT_SITTING_DURATION_MIN = 10;

export enum SuspensionType {
  Duration = 1,
  Until
}

export enum SuspensionOptions {
  SUSPEND = 1,
  END
}

export const DIVISION_TYPE_OPTIONS = [
  {
    title: 'SUSPENSION_DIALOG.CHOICE.OPTION_1',
    value: SuspensionOptions.SUSPEND
  },
  {
    title: 'SUSPENSION_DIALOG.CHOICE.OPTION_2',
    value: SuspensionOptions.END
  }
];
