import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import {
  UserRole,
  CbsErrorPageComponent,
  ErrorPageTypeId,
  ErrorNavigationPage
} from 'proceduralsystem-cbs-common-components';
import { NavigationPage } from '@app/app.types';
import { PageComponent } from './page.component';
import { StoreRoleGuardService } from '@app/services/guards/store-role-guard.service';
import { InChamberNoAccessPageComponent } from '@shared/components/in-chamber-error-page/in-chamber-no-access-page.component';

/**
 * MSAL Angular can protect routes in your application
 * using MsalGuard. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#secure-the-routes-in-your-application
 */
const routes: Routes = [
  {
    path: '',
    redirectTo: NavigationPage.Schedule,
    pathMatch: 'full'
  },
  {
    path: '',
    component: PageComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: NavigationPage.Schedule,
        loadChildren: () =>
          import('@app/pages/schedule/schedule.module').then(
            esModule => esModule.ScheduleModule
          ),
        canActivate: [StoreRoleGuardService],
        data: {
          roles: [UserRole.CeannComhairle, UserRole.ChamberClerk]
        }
      },
      {
        path: NavigationPage.ReferenceNotes,
        loadChildren: () =>
          import('@app/pages/reference-notes/reference-notes.module').then(
            esModule => esModule.ReferenceNotesModule
          ),
        canActivate: [StoreRoleGuardService],
        data: {
          roles: [UserRole.CeannComhairle, UserRole.ChamberClerk]
        }
      },
      {
        path: NavigationPage.BusinessItemView,
        loadChildren: () =>
          import(
            '@app-pages/business-item-view/business-item-view.module'
          ).then(esModule => esModule.BusinessItemViewModule),
        canActivate: [StoreRoleGuardService],
        data: {
          roles: [UserRole.CeannComhairle, UserRole.ChamberClerk]
        }
      },
      {
        path: NavigationPage.SOSView,
        loadChildren: () =>
          import('@app-pages/sos-view/sos-view.module').then(
            esModule => esModule.SosViewModule
          ),
        canActivate: [StoreRoleGuardService],
        data: {
          roles: [UserRole.CeannComhairle, UserRole.ChamberClerk]
        }
      },
      {
        path: `${NavigationPage.Schedule}/:scheduleId/${NavigationPage.DivisionView}/:id`,
        loadChildren: () =>
          import('@app-pages/division-view/division-view.module').then(
            esModule => esModule.DivisionViewModule
          ),
        canActivate: [StoreRoleGuardService],
        data: {
          roles: [UserRole.CeannComhairle, UserRole.ChamberClerk]
        }
      },
      {
        path: ErrorNavigationPage.PageNotFound,
        component: CbsErrorPageComponent,
        pathMatch: 'full',
        data: {
          typeId: ErrorPageTypeId.NotFound
        }
      },
      {
        path: '**',
        component: InChamberNoAccessPageComponent,
        pathMatch: 'full'
      }
    ]
  }
];

export const routing = RouterModule.forRoot(routes, {
  useHash: false
});
