import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import {
  ProgressBarService,
  LanguageType,
  IScheduleRota,
  BusinessItemTypeId,
  selectChamberBell,
  ChamberBell,
  ChamberBellStatusId,
  StopBellActionId,
  ChamberBellTypeId,
  ScheduleItem
} from 'proceduralsystem-cbs-common-components';
import {
  OirHttpService,
  OirDisplayError,
  ToastNotificationsService,
  OirHTTPErrorResponse
} from 'proceduralsystem-clientcomponents';
import { IDailSchedule } from './schedule.types';
import { Store } from '@ngrx/store';
import { InAppState } from '@app/app.types';
import { ScheduleDialogsService } from './schedule-dialogs.service';
import { BellService } from '@app/services/bell.service';
import { SuspensionOptions } from '@app/shared/dialogs/suspension-dialog/suspension-dialog.types';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {
  constructor(
    private readonly oirHttp: OirHttpService,
    private readonly toastService: ToastNotificationsService,
    private readonly progressBarService: ProgressBarService,
    private readonly translate: TranslateService,
    private readonly store: Store<InAppState>,
    private readonly scheduleDialogService: ScheduleDialogsService,
    private readonly bellService: BellService
  ) {}

  fetchDailSchedule(hideSpinner = false): Observable<IDailSchedule> {
    return this.oirHttp
      .get<IDailSchedule>({
        path: `/api/schedule/dail`,
        params: {
          lang: this.translate.currentLang || LanguageType.en
        },
        hideSpinner
      })
      .pipe(
        map((v: IDailSchedule) => this.adjustDailScheduleDurations(v)),
        catchError((error: OirDisplayError | OirHTTPErrorResponse) => {
          if (!(error instanceof OirHTTPErrorResponse && error.code == '404')) {
            this.toastService.addNotification({
              title: 'COMMON.REQUESTERROR',
              error: (error as OirDisplayError).detail
                ? (error as OirDisplayError).detail
                : ((error as OirHTTPErrorResponse).cause.detail as string)
            });
          }

          return of(null);
        })
      );
  }

  fetchRotaDay(path: 'clerk' | 'chair'): Observable<IScheduleRota> {
    return this.oirHttp
      .get<{ rotaDay: IScheduleRota }>({
        path: `/api/schedule/${path}Rota/rotaday/preview`,
        query: {
          lang: this.translate.currentLang || LanguageType.en
        }
      })
      .pipe(
        map((i: any) => i.rotaDay),
        map(this.adjustRotaDurations),
        catchError(() => of(null))
      );
  }

  suspendSchedule(
    id: number,
    durationPlanned: number,
    until: string,
    action: 'suspend' | 'extendSuspension',
    type: SuspensionOptions | null
  ): Observable<number> {
    const isBusinessItemStopped = type
      ? type !== SuspensionOptions.SUSPEND
      : type;

    return this.oirHttp
      .put<number>({
        path: `/api/schedule/dail/${action}`,
        body: {
          id,
          durationPlanned,
          until,
          isBusinessItemStopped
        }
      })
      .pipe(
        catchError(error => {
          this.toastService.addNotification({
            title: 'COMMON.REQUESTERROR',
            error: error.detail
          });

          return of(-1);
        })
      );
  }

  adjournSchedule(id: number): Observable<number> {
    return this.oirHttp
      .put<number>({ path: '/api/schedule/dail/adjourn', body: { id } })
      .pipe(
        tap(result => {
          if (result > -1) {
            this.toastService.addNotification({
              title: 'SCHEDULE.TN13.DIALOG.ADJOURNMENT.NOTIFICATION'
            });
          }
        }),
        catchError(error => {
          this.toastService.addNotification({
            title: 'COMMON.REQUESTERROR',
            error: error.detail
          });

          return of(-1);
        })
      );
  }

  resumeSchedule(id: number): Observable<number> {
    return this.oirHttp
      .put<number>({ path: '/api/schedule/dail/resume', body: { id } })
      .pipe(
        catchError(error => {
          this.toastService.addNotification({
            title: 'COMMON.REQUESTERROR',
            error: error.detail
          });

          return of(-1);
        })
      );
  }

  startBusinessItem(
    scheduleId: number,
    id: number,
    instanceId: number
  ): Observable<number> {
    return this.store.select(selectChamberBell).pipe(
      take(1),
      switchMap((chamberBell: ChamberBell) => {
        if (
          chamberBell &&
          chamberBell.statusId === ChamberBellStatusId.InProgress
        ) {
          if (
            [ChamberBellTypeId.Division, ChamberBellTypeId.Voting].includes(
              chamberBell.typeId
            )
          ) {
            const confirmButton = 'DIALOG.YES.BUTTON';
            return this.scheduleDialogService
              .getCancelDivisionDialog(confirmButton)
              .pipe(
                switchMap(data => {
                  if (data !== confirmButton) {
                    return of(0);
                  }
                  return this.stopBell(chamberBell, scheduleId, id);
                })
              );
          }

          if (
            [ChamberBellTypeId.Assemble, ChamberBellTypeId.Quorum].includes(
              chamberBell.typeId
            )
          ) {
            return this.stopBell(chamberBell, scheduleId, id);
          }
        }

        return this.progressBarService.startBusinessItem(
          scheduleId,
          id,
          instanceId
        );
      }),
      catchError((error: OirDisplayError) => {
        this.toastService.addNotification({
          title: 'COMMON.REQUESTERROR',
          error: error.detail
        });

        return of(-1);
      })
    );
  }

  pauseBusinessItem(
    scheduleId: number,
    id: number,
    instanceId: number
  ): Observable<number> {
    return this.progressBarService.pauseBusinessItem(
      scheduleId,
      id,
      instanceId
    );
  }

  private stopBell(
    chamberBell: ChamberBell,
    scheduleId: number,
    id: number
  ): Observable<number> {
    const action =
      chamberBell.businessItemId === id
        ? StopBellActionId.CancelResume
        : StopBellActionId.CancelPlayNext;
    return this.bellService
      .stopBell(
        scheduleId,
        chamberBell.validStopActions.includes(action)
          ? action
          : StopBellActionId.Cancel
      )
      .pipe(switchMap(() => of(1)));
  }

  private adjustDailScheduleDurations(schedule: IDailSchedule): IDailSchedule {
    return {
      ...schedule,
      scheduleItems: schedule.scheduleItems.map((si, i, arr) => ({
        ...si,
        businessItem: si.businessItem && {
          ...si.businessItem,
          durationPlanned:
            si.businessItem.durationPlanned &&
            si.businessItem.durationPlanned / 60,
          sharedSlot: this.findAndAssignFirstSharedSlot(arr, si, i)
        }
      }))
    };
  }

  private findAndAssignFirstSharedSlot(
    data: ScheduleItem[],
    currentValue: ScheduleItem,
    currentIndex: number
  ): boolean {
    const hasBusinessItem =
      data.findIndex(() => {
        return (
          data
            .map(v => (v.businessItem ? v.businessItem.position : null))
            .indexOf(currentValue?.businessItem?.position) === currentIndex
        );
      }) > -1;

    if (
      currentValue.businessItem &&
      currentValue.businessItem.sharedSlot &&
      hasBusinessItem
    ) {
      return false;
    }

    return currentValue?.businessItem?.typeId ===
      BusinessItemTypeId.ChamberSuspended
      ? false
      : currentValue?.businessItem?.sharedSlot;
  }

  private adjustRotaDurations(rota: IScheduleRota): IScheduleRota {
    if (!rota) {
      return rota;
    }

    return {
      ...rota,
      rotaBusinessItems:
        rota.rotaBusinessItems &&
        rota.rotaBusinessItems.map(bi => ({
          ...bi,
          duration: bi.duration && (bi.duration *= 60),
          rotaSlotMembers: bi.rotaSlotMembers
            ? bi.rotaSlotMembers.map(sm => ({
                ...sm,
                duration: sm.duration * 60
              }))
            : null
        }))
    };
  }
}
