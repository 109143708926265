import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { OirHttpService } from 'proceduralsystem-clientcomponents';
import { ILiveView } from '@app/store/reducers/live-sharing.reducer';

@Injectable({
  providedIn: 'root'
})
export class ActiveUsersService {
  constructor(private oirHttp: OirHttpService) {}

  fetchLiveViewData(): Observable<ILiveView[]> {
    return this.oirHttp
      .get<ILiveView[]>('/api/LiveSharing/getLiveViews')
      .pipe(shareReplay(1));
  }
}
