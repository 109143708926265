import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import {
  distinctUntilChanged,
  combineLatestWith,
  startWith,
  takeUntil
} from 'rxjs/operators';
import {
  DIVISION_TYPE_OPTIONS,
  MAX_DIVISION_DURATION,
  DIVISION_BELL_OPTIONS,
  ICallDivisionDialogData,
  DIVISION_DEFAULT_DURATION,
  DivisionBellType
} from './call-division-bell-dialog.types';
import { TranslateService } from '@ngx-translate/core';
import { ChamberBellSubTypeId } from 'proceduralsystem-cbs-common-components';
import { BellService } from '@app/services/bell.service';
import { SelectValue } from 'proceduralsystem-clientcomponents';

@Component({
  selector: 'oir-call-division-bell-dialog',
  templateUrl: './call-division-bell-dialog.component.html',
  styleUrls: ['./call-division-bell-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallDivisionBellDialogComponent implements OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();

  OPTIONS = DIVISION_TYPE_OPTIONS;
  BELL_OPTIONS = DIVISION_BELL_OPTIONS.map(v => ({
    ...v,
    title: this.translate.instant(v.title)
  }));
  ChamberBellSubTypeId = ChamberBellSubTypeId;

  divisionForm: FormGroup;
  stageList: SelectValue<number>[] = [];
  availableSubtypes: ChamberBellSubTypeId[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ICallDivisionDialogData,
    private cd: ChangeDetectorRef,
    private readonly translate: TranslateService,
    private readonly bellService: BellService
  ) {
    this.createFormGroup();
    this.listenToFormValues();
    this.handleDialogData();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  resetForm(): void {
    this.divisionForm.reset({
      duration: DIVISION_DEFAULT_DURATION,
      stage: null
    });
  }

  private handleDialogData(): void {
    if (this.data && this.data.context) {
      if (this.data.context.bells) {
        this.availableSubtypes = this.data.context.bells.map(
          bell => bell.chamberBellSubTypeId
        );
      }
      if (this.data.context.scheduleId) {
        this.getStages(this.data.context.scheduleId);
      }
    }
  }

  private createFormGroup(): void {
    this.divisionForm = new FormGroup({
      type: new FormControl(null, Validators.required),
      bellType: new FormControl(null, Validators.required),
      stage: new FormControl(null, Validators.required),
      duration: new FormControl(DIVISION_DEFAULT_DURATION, [
        Validators.required,
        Validators.max(MAX_DIVISION_DURATION),
        Validators.min(
          (this.data.context && this.data.context.minDuration) || 1
        ),
        Validators.pattern('^(0|[1-9][0-9]*)$')
      ]),
      associatedDivision: new FormControl(false)
    });
  }

  private listenToFormValues(): void {
    this.divisionForm
      .get('type')
      .valueChanges.pipe(
        distinctUntilChanged(),
        combineLatestWith(
          this.divisionForm
            .get('associatedDivision')
            .valueChanges.pipe(distinctUntilChanged(), startWith(null))
        ),
        takeUntil(this._destroy$)
      )
      .subscribe(([type, toggleValue]) => {
        const stageCtrl = this.divisionForm.get('stage');

        if (type === DIVISION_TYPE_OPTIONS[0].value) {
          this.divisionForm.enable();
          this.divisionForm.get('duration').patchValue(0);
          this.divisionForm.get('duration').disable();
          this.divisionForm.get('associatedDivision').disable();
        } else if (type === DIVISION_TYPE_OPTIONS[1].value) {
          this.divisionForm.enable();
          this.divisionForm
            .get('duration')
            .patchValue(DIVISION_DEFAULT_DURATION);
          stageCtrl.disable();

          if (
            !this.availableSubtypes.includes(
              ChamberBellSubTypeId.DivisionGuillotine
            )
          ) {
            this.divisionForm.get('associatedDivision').disable();
          }
        } else {
          this.divisionForm.disable();
          this.divisionForm.get('type').enable();
        }

        if (toggleValue && this.data.context) {
          stageCtrl.enable();
        }

        if (!this.stageList.length) {
          stageCtrl.reset(null, { emitEvent: false });
          stageCtrl.disable();
        } else if (this.stageList.length === 1 && stageCtrl.enabled) {
          stageCtrl.setValue(this.stageList[0]);
          stageCtrl.disable();
        }
      });

    this.divisionForm
      .get('bellType')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this._destroy$))
      .subscribe((bellType: SelectValue<number>) => {
        switch (bellType && bellType.value) {
          case DivisionBellType.Long:
            this.resetDurationMinValidator(11);
            return;
          case DivisionBellType.Manual:
            this.resetDurationMinValidator(7);
            return;
          case DivisionBellType.Short:
            this.resetDurationMinValidator(4);
            return;
          default:
            this.resetDurationMinValidator(3);
            return;
        }
      });
  }

  private resetDurationMinValidator(minDuration: number): void {
    const durationCtrl = this.divisionForm.get('duration');

    durationCtrl.clearValidators();
    durationCtrl.addValidators([
      Validators.required,
      Validators.min(minDuration),
      Validators.max(MAX_DIVISION_DURATION),
      Validators.pattern('^(0|[1-9][0-9]*)$')
    ]);
    durationCtrl.updateValueAndValidity();
  }

  private getStages(scheduleId: number): void {
    this.bellService.getDivisionStage(scheduleId).subscribe(resp => {
      this.stageList =
        resp.stages.map(stage => ({
          ...{ title: stage.name, value: stage.id }
        })) || [];
      this.cd.detectChanges();
    });
  }
}
