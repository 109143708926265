<div class="chair-view-container {{ isChairView ? 'chair-view--open' : '' }}">
    <div class="chair-view-action-container">
        <div class="overline">
            {{ 'CHAT.CHAIR_VIEW.TITLE' | translate }}
        </div>
        <mat-slide-toggle class="switch-mat" (change)="changeChairView($event)"></mat-slide-toggle>
    </div>
    <div *ngIf="isChairView" class="chair-view">
      <video id="player" class="azuremediaplayer amp-default-skin" autoplay width="368px">
        <p class="amp-no-js">
          To view this video please enable JavaScript, and consider upgrading to a web browser that supports HTML5 video
        </p>
      </video>
    </div>
</div>
