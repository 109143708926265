import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';
import {
  ScheduleStatusId,
  IrelandTimezone,
  REQUEST_DATE_FORMAT
} from 'proceduralsystem-cbs-common-components';
import {
  DEFAULT_SITTING_DURATION_MIN,
  SuspensionType,
  DIVISION_TYPE_OPTIONS
} from './suspension-dialog.types';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';

const UntilRegex = /(^([0-1]?\d|2[0-3]):[0-5]\d$)/;

@Component({
  selector: 'oir-suspension-dialog',
  templateUrl: './suspension-dialog.component.html',
  styleUrls: ['./suspension-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuspensionDialogComponent {
  private _status: ScheduleStatusId;

  @Input() set status(value: ScheduleStatusId) {
    this._status = value;

    if (value === ScheduleStatusId.Suspended) {
      this.title = 'SUSPENSION_DIALOG.EXTEND_SUSPENSION_TITLE';
      this.message = 'SUSPENSION_DIALOG.EXTEND_SUSPENSION_MESSAGE';
      this.confirmButton = 'SUSPENSION_DIALOG.BUTTON.EXTEND_SUSPENSION_CONFIRM';
    } else {
      this.form.get('type').addValidators(Validators.required);
      this.showNextPage = false;
    }
  }
  get status(): ScheduleStatusId {
    return this._status;
  }

  form = new FormGroup({
    type: new FormControl(null),
    suspensionType: new FormControl(SuspensionType.Duration),
    duration: new FormControl(
      DEFAULT_SITTING_DURATION_MIN,
      Validators.compose([
        Validators.required,
        Validators.max(999),
        Validators.min(1)
      ])
    ),
    until: new FormControl(
      moment().add(DEFAULT_SITTING_DURATION_MIN, 'minutes').format('HH:mm'),
      Validators.compose([
        Validators.required,
        Validators.pattern(UntilRegex),
        this.selectedTimeIsBetweenCurrentFuture12H.bind(this)
      ])
    )
  });

  title = 'SUSPENSION_DIALOG.TITLE';
  message = 'SUSPENSION_DIALOG.SUSPEND_SITTING_MESSAGE';
  messageAlt = 'SUSPENSION_DIALOG.SUSPEND_SITTING_MESSAGE_ALT';
  confirmButton = 'SUSPENSION_DIALOG.BUTTON.CONFIRM';

  durationMask = [/\d/, /\d/, /\d/];
  untilTimeMask = [/\d/, /\d/, ':', /\d/, /\d/];
  SuspensionType = SuspensionType;
  ScheduleStatusId = ScheduleStatusId;

  OPTIONS = DIVISION_TYPE_OPTIONS.map(v => ({
    ...v,
    title: this.translate.instant(v.title)
  }));

  showNextPage = true;

  constructor(private readonly translate: TranslateService) {}

  private isInCorrectTimeFormat(value: string): boolean {
    if (!value) {
      return false;
    }

    const regex = new RegExp(UntilRegex);

    return regex.test(value);
  }

  private selectedTimeIsBetweenCurrentFuture12H(
    control: AbstractControl
  ): ValidationErrors | null {
    if (!control || !control.value) {
      return { required: true };
    }

    const selectedTime = control.value;

    if (!this.isInCorrectTimeFormat(selectedTime)) {
      return { pattern: true };
    }

    const tmpDate = moment().tz(IrelandTimezone);
    const currentTime = moment().tz(IrelandTimezone);
    const maxFutureTime = moment().tz(IrelandTimezone).add(12, 'hours');

    if (
      moment(tmpDate.format(REQUEST_DATE_FORMAT) + ' ' + selectedTime).isBefore(
        currentTime
      )
    ) {
      tmpDate.add(1, 'day');
    }

    if (
      moment(
        tmpDate.format(REQUEST_DATE_FORMAT) + ' ' + selectedTime
      ).isBetween(currentTime, maxFutureTime, 'minutes', '[]')
    ) {
      return null;
    }

    return { futureTime: true };
  }

  getFormValues(): { duration?: number; until?: string; type: SuspensionType } {
    const formValues = this.form.getRawValue();
    const selectedSuspensionType: SuspensionType = formValues.suspensionType;
    const obj = { type: formValues.type };

    return selectedSuspensionType === SuspensionType.Duration
      ? { ...obj, duration: formValues.duration * 60 }
      : { ...obj, until: this.getUpdatedUntilValue(formValues.until) };
  }

  private getUpdatedUntilValue(value: string): string {
    if (!this.isInCorrectTimeFormat(value)) {
      return null;
    }

    const currentDate = moment().utc();
    const untilDate = moment.utc(
      currentDate.format('YYYY-MM-DD') + ' ' + value
    );
    if (untilDate.isBefore(currentDate)) {
      untilDate.add(1, 'day');
    }
    return untilDate.toISOString();
  }

  resetForm(): void {
    this.form.reset({
      suspensionType: SuspensionType.Duration,
      duration: DEFAULT_SITTING_DURATION_MIN,
      until: moment()
        .add(DEFAULT_SITTING_DURATION_MIN, 'minutes')
        .format('HH:mm')
    });
    this.showNextPage = false;
  }

  onNextPageClick(): void {
    this.showNextPage = true;
  }
}
