import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewEncapsulation
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IOirDialogData } from 'proceduralsystem-clientcomponents';

@Component({
  selector: 'oir-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ConfirmationDialogComponent {
  isConfirmed = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: IOirDialogData) {}
}
