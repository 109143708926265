import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  OnDestroy
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ILiveScheduleBI,
  ChamberBell,
  ChamberBellTypeId,
  ChamberBellSubTypeId
} from 'proceduralsystem-cbs-common-components';
import { IOirDialogData } from 'proceduralsystem-clientcomponents';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EndingBellTypes } from '@app/app.types';

@Component({
  selector: 'oir-quorum-ending-dialog',
  templateUrl: './quorum-ending-dialog.component.html',
  styleUrls: ['./quorum-ending-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuorumEndingDialogComponent implements OnDestroy {
  private _destroy$ = new Subject<void>();
  dialogName = 'cancelQuorumDialog';
  ChamberBellTypeId = ChamberBellTypeId;
  ChamberBellSubTypeId = ChamberBellSubTypeId;
  EndingBellTypes = EndingBellTypes;
  scheduleForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: IOirDialogData) {
    if (this.data && this.data.context && this.data.context.dialogName) {
      this.dialogName = this.data.context.dialogName;
    }
    this.createFormGroup();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  get businessItems(): ILiveScheduleBI[] {
    return (this.data.messageParams.action.businessItems ||
      []) as ILiveScheduleBI[];
  }

  get bellTypes(): ChamberBell {
    return (this.data.messageParams.action.ongoingChamberBell ||
      {}) as ChamberBell;
  }

  get isVoteOver(): boolean {
    return this.data && this.data.context && this.data.context.isVoteOver;
  }

  createFormGroup(): void {
    this.scheduleForm = new FormGroup({
      selectedBusinessItem: new FormControl(this.businessItems[0]),
      duration: new FormControl({ value: null, disabled: true }, [
        Validators.maxLength(2),
        Validators.required
      ]),
      choice: new FormControl(EndingBellTypes.Resume, Validators.required)
    });
    this.scheduleForm
      .get('choice')
      .valueChanges.pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        if (value !== EndingBellTypes.Extend) {
          this.scheduleForm.get('duration').disable();
        } else {
          this.scheduleForm.get('duration').enable();
        }
        this.scheduleForm
          .get('selectedBusinessItem')
          .setValue(this.businessItems[value - 1], { emitEvent: false });
      });
  }
}
