import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  DialogPosition,
  OirAuthService
} from 'proceduralsystem-clientcomponents';
import {
  AccessOperation,
  DiscardChangesDialogComponent,
  IBackButton,
  UserRole
} from 'proceduralsystem-cbs-common-components';
import { InAppState, NavigationPage } from '@app/app.types';
import { InCbsNavigationService } from '@app/services/in-cbs-navigation.service';
import { selectChamberUser } from '@app/store/reducers/live-sharing.reducer';
import { InCbsUserService } from '@app/services/in-cbs-user.service';
import { MatDialog } from '@angular/material/dialog';
import { ActiveUsersComponent } from '../active-users/active-users.component';

@Component({
  selector: 'oir-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderBarComponent {
  accessOperations: AccessOperation[] = [];

  constructor(
    public navigationService: InCbsNavigationService,
    private dialog: MatDialog,
    private authService: OirAuthService,
    private inCbsUserService: InCbsUserService,
    private store: Store<InAppState>,
    private cd: ChangeDetectorRef
  ) {
    if (
      this.authService.hasSomeRoles([
        UserRole.CeannComhairle,
        UserRole.ChamberClerk
      ])
    ) {
      this.store.select(selectChamberUser).subscribe(user => {
        if (user) {
          this.accessOperations = [AccessOperation.View];
          this.cd.markForCheck();
        }
      });
    }
  }

  clockOutUser(): void {
    this.inCbsUserService.openUserClockOutDialog();
  }

  clockInUser(): void {
    this.inCbsUserService.openUserClockInDialog();
  }

  onBackButtonClick(options: IBackButton): void {
    // Ignore pages as it has own Guard implementation and has unsaved
    if (
      ![NavigationPage.BusinessItemView].includes(
        this.navigationService.currentPage$.value as NavigationPage
      ) &&
      this.navigationService.unsavedChanges$.value
    ) {
      const dialogRef = this.dialog.open(DiscardChangesDialogComponent, {
        hasBackdrop: true,
        disableClose: true,
        position: DialogPosition.CENTER,
        panelClass: 'dialog-mat'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.navigationService.navigateBackBasedOnParentRoute(options.path);
        }
      });
    } else {
      this.navigationService.navigateBackBasedOnParentRoute(options.path);
    }
  }

  openActiveUsers(): void {
    this.dialog.open(ActiveUsersComponent, {
      hasBackdrop: true,
      position: DialogPosition.TOP_RIGHT,
      panelClass: 'sidebar-dialog',
      backdropClass: 'sidebar-dialog-overlay-dark'
    });
  }
}
