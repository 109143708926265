import { createAction, props } from '@ngrx/store';
import { IBellTimer } from '@app/app.types';

export const startBellTimer = createAction(
  '[Bell Timer] Start',
  props<IBellTimer>()
);
export const updateBellTimer = createAction(
  '[Bell Timer] Update',
  props<{ currentTime: number }>()
);
export const stopBellTimer = createAction('[Bell Timer] Stop');
