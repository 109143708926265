import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { selectVideoUrl } from '@app/store/reducers/chat.reducer';
import { Store } from '@ngrx/store';
import { InAppState } from '@app/app.types';

@Component({
  selector: 'oir-chair-view',
  templateUrl: './chair-view.component.html',
  styleUrls: ['./chair-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChairViewComponent {
  private player: amp.Player;

  @Input() set isChairViewOpen(value: boolean) {
    this.isChairView = value;
    this.cd.detectChanges();
    if (value) {
      if (!this.player) {
        this.initPlayer();
      }
      this.player.play();
    }
  }
  @Output() toggleChairView = new EventEmitter<boolean>();

  videoUrl: string;
  isChairView: boolean;

  constructor(private store: Store<InAppState>, private cd: ChangeDetectorRef) {
    this.store.select(selectVideoUrl).subscribe(v => (this.videoUrl = v));
  }

  changeChairView(event: MatSlideToggleChange) {
    this.toggleChairView.emit(event.checked);
    if (!event.checked && this.player) {
      this.player.pause();
    }
  }

  private initPlayer() {
    this.player = amp('player', {
      controls: true,
      customPlayerSettings: { nativeControlsForTouch: false },
      autoplay: false,
      logo: { enabled: false },
      poster: './assets/images/oireachtas_tv.jpg'
    });
    this.player.src([{ src: this.videoUrl, type: 'video/mp4' }]);
  }
}
