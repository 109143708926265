import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { LiveBadgeModule } from 'proceduralsystem-cbs-common-components';
import { ProceduralsystemClientcomponentsModule } from 'proceduralsystem-clientcomponents';
import { InChamberSideNavigationComponent } from './in-chamber-side-navigation.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    MatIconModule,
    ProceduralsystemClientcomponentsModule,
    LiveBadgeModule
  ],
  declarations: [InChamberSideNavigationComponent],
  exports: [InChamberSideNavigationComponent]
})
export class InChamberSideNavigationModule {}
