import { createAction, props } from '@ngrx/store';
import { IChamberUser } from 'proceduralsystem-cbs-common-components';
import { ILiveView } from '../reducers/live-sharing.reducer';
import { NavigationPage } from '@app/app.types';
import { ILiveViewData } from '@app/pages/business-item-view/business-item-view.types';

const ACTION_NAME = '[LIVE SHARING ACTION]';
export const INIT_LIVE_VIEW = `${ACTION_NAME} Init Live Views`;
export const SET_LIVE_VIEW = `${ACTION_NAME} Set Live View`;
export const POST_LIVE_VIEW = `${ACTION_NAME} Post Live View`;
export const USER_CLOCKED_IN = `${ACTION_NAME} User Clocked-in`;
export const USER_CLOCKED_OUT = `${ACTION_NAME} User Clocked-out`;
export const SET_CONNECTION_ID = `${ACTION_NAME} Set Connection Id`;
export const SET_CHAMBER_USER = `${ACTION_NAME} Set Chamber User`;

export const postLiveView = createAction(
  POST_LIVE_VIEW,
  props<{
    page: NavigationPage;
    data: ILiveViewData;
  }>()
);
export const initLiveView = createAction(
  INIT_LIVE_VIEW,
  props<{ liveViews: ILiveView[] }>()
);
export const setLiveView = createAction(
  SET_LIVE_VIEW,
  props<{ liveView: ILiveView }>()
);
export const userClockedIn = createAction(
  USER_CLOCKED_IN,
  props<{ chamberUser: IChamberUser }>()
);
export const userClockedOut = createAction(
  USER_CLOCKED_OUT,
  props<{ chamberUser: IChamberUser }>()
);
export const setConnectionId = createAction(
  SET_CONNECTION_ID,
  props<{ connectionId: string }>()
);
export const setChamberUser = createAction(
  SET_CHAMBER_USER,
  props<{ chamberUser: IChamberUser }>()
);
