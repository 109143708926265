import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  OnDestroy
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  Subject,
  distinctUntilChanged,
  pairwise,
  startWith,
  takeUntil
} from 'rxjs';
import {
  QUORUM_TYPE_OPTIONS,
  MAX_QUORUM_DURATION,
  ICallQuorumDialogData,
  QUORUM_DEFAULT_DURATION
} from './call-quorum-bell-dialog.types';

@Component({
  selector: 'oir-call-quorum-bell-dialog',
  templateUrl: './call-quorum-bell-dialog.component.html',
  styleUrls: ['./call-quorum-bell-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallQuorumBellDialogComponent implements OnDestroy {
  private destroy$: Subject<void> = new Subject();

  LIGHT_GREY_COLOR = 'lightgrey';
  INHERIT_COLOR = 'inherit';
  OPTIONS = QUORUM_TYPE_OPTIONS;

  quorumForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ICallQuorumDialogData) {
    this.createFormGroup();
    this.listenToFormValues();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  resetForm(): void {
    this.quorumForm.reset({
      type: this.OPTIONS[0].value,
      duration: QUORUM_DEFAULT_DURATION
    });
  }

  private createFormGroup(): void {
    this.quorumForm = new FormGroup({
      type: new FormControl(this.OPTIONS[0].value, Validators.required),
      duration: new FormControl(
        {
          value: QUORUM_DEFAULT_DURATION,
          disabled: true
        },
        [
          Validators.required,
          Validators.max(MAX_QUORUM_DURATION),
          Validators.min(
            (this.data.context && this.data.context.minDuration) || 1
          ),
          Validators.pattern(
            '^(0|[0-9]|[1-9][0-9]|[0-9].[05]|[1-9][0-9].[05])$'
          )
        ]
      )
    });
  }

  private listenToFormValues(): void {
    this.quorumForm
      .get('type')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(type => {
        if (type === QUORUM_TYPE_OPTIONS[0].value) {
          this.quorumForm.get('duration').disable();
        } else {
          this.quorumForm.get('duration').enable();
        }
      });

    this.quorumForm
      .get('duration')
      .valueChanges.pipe(
        startWith(QUORUM_DEFAULT_DURATION),
        distinctUntilChanged(),
        pairwise(),
        takeUntil(this.destroy$)
      )
      .subscribe(([prevValue, currentValue]) => {
        if (!Number.isNaN(currentValue)) {
          const decimalPart = currentValue.split('.')?.[1] || '';

          if (currentValue > MAX_QUORUM_DURATION || decimalPart.length > 1) {
            this.quorumForm
              .get('duration')
              .patchValue(prevValue, { onlySelf: true });
          }
        }
      });
  }

  get durationDisabledColor(): string {
    return this.quorumForm.get('type').value === this.OPTIONS[0].value
      ? this.LIGHT_GREY_COLOR
      : this.INHERIT_COLOR;
  }
}
