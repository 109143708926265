import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ProceduralsystemClientcomponentsModule } from 'proceduralsystem-clientcomponents';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ChatComponent } from './chat.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TextFieldModule } from '@angular/cdk/text-field';
import { TranslateModule } from '@ngx-translate/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { ChairViewComponent } from './components/chair-view/chair-view.component';
import { ChatButtonComponent } from './components/chat-button/chat-button.component';
import { ChatMessageComponent } from './components/chat-message/chat-message.component';

@NgModule({
  declarations: [
    ChatComponent,
    ChairViewComponent,
    ChatMessageComponent,
    ChatButtonComponent
  ],
  imports: [
    CommonModule,
    ProceduralsystemClientcomponentsModule,
    MatIconModule,
    MatButtonModule,
    MatSlideToggleModule,
    TextFieldModule,
    TranslateModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule
  ],
  providers: [DatePipe],
  exports: [ChatButtonComponent]
})
export class ChatModule {}
