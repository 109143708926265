import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { ProceduralsystemClientcomponentsModule } from 'proceduralsystem-clientcomponents';
import { ProceduralsystemCbsCommonComponentsModule } from 'proceduralsystem-cbs-common-components';
import { SwitchUserComponent } from './components/dialog/switch-user/switch-user.component';
import { HeaderBarComponent } from './header-bar.component';
import { ChamberUserInfoComponent } from './components/chamber-info/chamber-user-info.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ProceduralsystemClientcomponentsModule,
    ProceduralsystemCbsCommonComponentsModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatInputModule,
    ReactiveFormsModule
  ],
  declarations: [
    HeaderBarComponent,
    SwitchUserComponent,
    ChamberUserInfoComponent
  ],
  exports: [HeaderBarComponent, SwitchUserComponent]
})
export class HeaderBarModule {}
