import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on
} from '@ngrx/store';
import { IChamberUser } from 'proceduralsystem-cbs-common-components';
import * as LiveSharingAction from '../actions/live-sharing.actions';
import { NavigationPage } from '@app/app.types';
import { IGeneralLiveView } from '@app/pages/business-item-view/business-item-view.types';

export type ILiveView = IGeneralLiveView<NavigationPage>;

export const liveSharingFeatureKey = 'live-sharing';

export interface LiveSharingState {
  liveViews: ILiveView[];
  chamberUser: IChamberUser;
  connectionId: string;
}

export const selectLiveSharing = createFeatureSelector<LiveSharingState>(
  liveSharingFeatureKey
);
export const selectLiveViews = createSelector(
  selectLiveSharing,
  (state: LiveSharingState) => state.liveViews
);
export const selectChamberUser = createSelector(
  selectLiveSharing,
  (state: LiveSharingState) => state.chamberUser
);
export const selectConnectionId = createSelector(
  selectLiveSharing,
  (state: LiveSharingState) => state.connectionId
);
export const initialState: LiveSharingState = {
  liveViews: [],
  chamberUser: null,
  connectionId: null
};

export const liveSharingReducer = createReducer(
  initialState,
  on(LiveSharingAction.initLiveView, (state, { liveViews }) => {
    return {
      ...state,
      liveViews: state.chamberUser
        ? liveViews.filter(
            v => v.user.userObjectId !== state.chamberUser.userObjectId
          )
        : liveViews
    };
  }),
  on(LiveSharingAction.setLiveView, (state, { liveView }) => {
    const otherViews = state.liveViews.filter(
      v => v.user.userObjectId !== liveView.user.userObjectId
    );

    if (!state.chamberUser) {
      return {
        ...state,
        liveViews: [...otherViews]
      };
    }

    return {
      ...state,
      liveViews:
        liveView.user.userObjectId == state.chamberUser.userObjectId
          ? [...otherViews]
          : [...otherViews, liveView]
    };
  }),
  on(LiveSharingAction.userClockedOut, (state, { chamberUser }) => {
    if (chamberUser) {
      return {
        ...state,
        liveViews: [
          ...state.liveViews.filter(
            v => v.user.userObjectId !== chamberUser.userObjectId
          )
        ]
      };
    }

    return state;
  }),
  on(LiveSharingAction.setConnectionId, (state, { connectionId }) => ({
    ...state,
    connectionId
  })),
  on(LiveSharingAction.setChamberUser, (state, { chamberUser }) => ({
    ...state,
    chamberUser
  }))
);
