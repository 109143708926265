<div class="modal-dialog call-quorum-bell-dialog ta_s_callQuorumDialog">
	<div class="modal-header">
		<h2 mat-dialog-title class="ta_s_callQuorumDialog_title">{{ data.title | translate }}</h2>
		<mat-icon [mat-dialog-close]="null" class="mat-close-icon ta_close" aria-label="close icon">close</mat-icon>
  </div>
  
	<mat-dialog-content class="modal-body">
		<div class="dialog-message ta_s_callQuorumDialog_message">
			<label [htmlFor]="bellForm">{{ data.message | translate }}</label>
    </div>

    <form [formGroup]="quorumForm" #bellForm>
      <mat-radio-group class="dialog-options ta_s_callQuorum_type" formControlName="type">
        <ng-container *ngFor="let option of OPTIONS; let i = index">
          <div *ngIf="i === 1" class="ta_s_callQuorumDialog_optionSeparator option-separator">
            <mat-divider></mat-divider><span class="separator-or">{{ "COMMON.OR" | translate }}</span><mat-divider></mat-divider>
          </div>
          <mat-radio-button class="ta_s_callQuorumDialog_type_option_{{i}} radio-button-mat subtitle-secondary" [value]="option.value">
            <p>{{ option.title | translate }}</p>
          </mat-radio-button>
        </ng-container>
      </mat-radio-group>

      <div class="input-container">
        <div [ngClass]="{'add-margin-bottom': true}"></div>
        <span class="input-label" [style.color]="durationDisabledColor">{{ 'CALL_QUORUM_DIALOG.DURATION.LABEL' | translate }}</span>
        <mat-form-field
          appearance="fill"
          class="input-field ta_s_callQuorumDialog_duration">
            <input
                matInput
                formControlName="duration"
                autoComplete="off"
                maxlength="4"
                [style.color]="durationDisabledColor"
                placeholder="{{'COMMON.TIME.MINUTES' | translate}}"
            />
            <span *ngIf="quorumForm.get('duration').value !== ''" [style.color]="durationDisabledColor">{{ 'COMMON.TIME.MINUTES' | translate }}.</span>
            <mat-hint [style.color]="durationDisabledColor">{{ 'CALL_QUORUM_DIALOG.DURATION.HINT' | translate }}</mat-hint>
        </mat-form-field> 
      </div>   
    </form>
  </mat-dialog-content>

    <mat-dialog-actions class="dialog-buttons">
      <button
        mat-button
        class="btn-basic ta_cancel"
        color="primary"
        [mat-dialog-close]="null"
        type="button">{{ data.cancelButton.name | translate | uppercase }}</button>
      <button
        mat-button
        class="btn-basic ta_confirm"
        color="primary"
        [disabled]="quorumForm.invalid"
        [mat-dialog-close]="quorumForm.value"
        type="button">{{ data.confirmButton.name | translate | uppercase}}</button>
    </mat-dialog-actions>
</div>


 
