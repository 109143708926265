import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ILiveScheduleBI,
  StopBellActionId
} from 'proceduralsystem-cbs-common-components';
import { IOirDialogData } from 'proceduralsystem-clientcomponents';

@Component({
  selector: 'oir-cancel-schedule-div-dialog',
  templateUrl: './cancel-schedule-div-dialog.component.html',
  styleUrls: ['./cancel-schedule-div-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CancelScheduleDivDialogComponent {
  dialogName = 'cancelScheduleDialog';
  selectedBusinessItem: ILiveScheduleBI;
  scheduleForm: FormGroup;
  scheduleId: number;
  action: StopBellActionId;

  constructor(@Inject(MAT_DIALOG_DATA) public data: IOirDialogData) {
    if (this.data && this.data.context && this.data.context.dialogName) {
      this.dialogName = this.data.context.dialogName;
    }
    this.createFormGroup();
  }

  get businessItems(): ILiveScheduleBI[] {
    return (this.data.messageParams || []) as ILiveScheduleBI[];
  }

  createFormGroup(): void {
    this.scheduleForm = new FormGroup({
      selectedBusinessItem: new FormControl(
        this.businessItems[0],
        Validators.required
      ),
      duration: new FormControl('', Validators.maxLength(2))
    });
  }
}
