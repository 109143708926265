<div class="modal-dialog switch-user-modal">
    <div class="modal-header">
        <h2 mat-dialog-title class="ta_su_modalTitle">
            {{ (type == 'clock-in' ? 'COMMON.CLOCK_IN' : 'COMMON.CLOCK_OUT') | translate }}
        </h2>
        <mat-icon [mat-dialog-close]="'COMMON.CANCEL'" class="mat-close-icon" aria-label="close icon">close</mat-icon>
    </div>

    <mat-dialog-content class="modal-body form-container">
      <form [formGroup]="form">
          <oir-typeahead *ngIf="type === 'clock-in'"
            [apiPath]="'/api/clerk'"
            [label]="'SWITCH_USER_NEW_CLERK.LABEL'"
            [hint]="'SWITCH_USER_NEW_CLERK.HINT'"
            [disabledEntryPostfix]="'CLOCK-IN.TYPEAHEAD.DISABLED.POSTFIX' | translate"
            titlePropertyName="fullName"
            formControlName="clerk"
            class="ta_su_clerkSelector">
          </oir-typeahead>
        <oir-typeahead *ngIf="type === 'clock-out'"
                       [label]="'SWITCH_USER_NEW_CLERK.LABEL'"
                       [disabled]="true"
                       titlePropertyName="fullName"
                       formControlName="clerk"
                       class="ta_su_clerkSelector">
        </oir-typeahead>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions class="dialog-buttons">
        <button
            mat-button
            class="btn-basic ta_su_cancelButton"
            color="primary"
            [mat-dialog-close]="'COMMON.CANCEL'">
            {{ 'COMMON.CANCEL' | translate | uppercase }}
        </button>
        <button
            mat-button
            class="btn-basic ta_su_confirmButton"
            color="primary"
            [disabled]="form.invalid || form.pristine"
            [mat-dialog-close]="form.getRawValue()">
            {{ (type == 'clock-in' ? 'COMMON.CLOCK_IN' : 'COMMON.CLOCK_OUT') | translate | uppercase }}
        </button>
    </mat-dialog-actions>
</div>
