import { IBusinessItem } from '@app/pages/schedule/schedule.types';
import { createAction, props } from '@ngrx/store';
import {
  ILiveScheduleBI,
  HubActionId
} from 'proceduralsystem-cbs-common-components';
import { ISpeakerParams } from '@app/app.types';

const ACTION_NAME = '[IN CHAMBER ACTION]';
export const CHAIR_DUE_TO_START = `${ACTION_NAME} Chair Due To Start`;
export const CLERK_DUE_TO_START = `${ACTION_NAME} Clerk Due To Start`;
export const RECALLED_SPEAKERS_LIST = `${ACTION_NAME} Recalled Speakers List`;
export const RECALLED_NOTES = `${ACTION_NAME} Recalled Notes`;
export const SPEAKER_LIST_SLOT_CHANGED = `${ACTION_NAME} Speaker List Slot Changed`;
export const CLOCK_SLOT_SPEAKER_CHANGED = `${ACTION_NAME} Clock Slot Speaker Changed`;
export const CLOCK_SLOT_CHANGED = `${ACTION_NAME} Clock Slot Changed`;
export const READY_TO_START_SCHEDULE_BUSINESS_ITEM = `${ACTION_NAME} Ready to start schedule business item`;
export const SPEAKER_LIST_CHANGED = `${ACTION_NAME} Speaker List Changed`;
export const CLIFF_NOTE_CHANGED = `${ACTION_NAME} Cliff Note Changed`;

export const chairDueToStart = createAction(
  CHAIR_DUE_TO_START,
  props<{ chairDueToStart: string[] }>()
);
export const clerkDueToStart = createAction(
  CLERK_DUE_TO_START,
  props<{ clerkDueToStart: string[] }>()
);
export const recalledSpeakersList = createAction(
  RECALLED_SPEAKERS_LIST,
  props<{ itemId: number }>()
);

export const recalledNotes = createAction(
  RECALLED_NOTES,
  props<{ itemId: number }>()
);

export const speakerListSlotStateChanged = createAction(
  SPEAKER_LIST_SLOT_CHANGED,
  props<{ params: ISpeakerParams }>()
);

export const clockSlotSpeakerStateChanged = createAction(
  CLOCK_SLOT_SPEAKER_CHANGED,
  props<{ clockParams: ISpeakerParams }>()
);

export const readyToStartBusinessItem = createAction(
  READY_TO_START_SCHEDULE_BUSINESS_ITEM,
  props<IBusinessItem | ILiveScheduleBI>()
);

export const speakersListStateChanged = createAction(
  SPEAKER_LIST_CHANGED,
  props<{
    changedSLBusinessItem: { businessItemId: number; status: number } | null;
  }>()
);

export const clockSlotStateChanged = createAction(
  CLOCK_SLOT_CHANGED,
  props<{
    changedClockItem: { businessItemId: number; status: number } | null;
  }>()
);

export const cliffNoteStateChanged = createAction(
  CLIFF_NOTE_CHANGED,
  props<{
    changedCliffNote: {
      businessItemId: number;
      status: number;
      actionId: HubActionId;
    } | null;
  }>()
);
