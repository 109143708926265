<div class="message-container-wrapper" [class.own-message]="message.userFullName === userFullName">
    <div class="message-container">
        <div class="message-wrapper ta_c_chatMessage">
            <div class="body-secondary">
                {{ message.message }}
            </div>
        </div>
        <div class="message-info caption-primary ta_c_chatMessageInfo">
            <div>
                {{ message.userFullName | translate }}
            </div>
            <div>
                {{ message.timestamp | date: 'hh:mm' }}
            </div>
        </div>
    </div>
</div>
