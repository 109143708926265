import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on
} from '@ngrx/store';
import { ScheduleStatusId } from 'proceduralsystem-cbs-common-components';
import * as ChatAction from '../actions/chat.actions';
import { ChatStatus, IChatMessage } from '@shared/components/chat/chat.types';

export const chatFeatureKey = 'chat';

export interface ChatState {
  chatStatus: ChatStatus;
  videoUrl: string;
  messages: IChatMessage[];
  scheduleStatusId: ScheduleStatusId;
}

export const selectChat = createFeatureSelector<ChatState>(chatFeatureKey);
export const selectVideoUrl = createSelector(
  selectChat,
  (state: ChatState) => state.videoUrl
);
export const selectChatMessages = createSelector(
  selectChat,
  (state: ChatState) => state.messages
);
export const selectScheduleStatusId = createSelector(
  selectChat,
  (state: ChatState) => state.scheduleStatusId
);
export const initialState: ChatState = {
  chatStatus: ChatStatus.NotActive,
  videoUrl: '',
  messages: null,
  scheduleStatusId: null
};

export const chatReducer = createReducer(
  initialState,
  on(ChatAction.setNewMessage, (state, { message }) => ({
    ...state,
    messages: [...state.messages, message]
  })),
  on(ChatAction.setMessages, (state, { messages }) => ({
    ...state,
    messages
  })),
  on(ChatAction.setChatStatus, (state, { chatStatus }) => ({
    ...state,
    chatStatus
  })),
  on(ChatAction.setVideoUrl, (state, { videoUrl }) => ({
    ...state,
    videoUrl
  })),
  on(ChatAction.setScheduleStatusId, (state, { scheduleStatusId }) => ({
    ...state,
    scheduleStatusId
  }))
);
