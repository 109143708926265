import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectChamberUser } from '@app/store/reducers/live-sharing.reducer';
import { ErrorNavigationPage } from 'proceduralsystem-cbs-common-components';
import { InAppState } from '@app/app.types';

@Injectable({
  providedIn: 'root'
})
export class StoreRoleGuardService implements CanActivate, CanActivateChild {
  constructor(
    private readonly store: Store<InAppState>,
    private readonly router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const routeRolesConfig: string[] = route.data.roles;
    if (routeRolesConfig) {
      return this.store.select(selectChamberUser).pipe(
        switchMap(user => {
          const shouldActivate =
            user && routeRolesConfig.some(item => item === user.role);
          if (!shouldActivate) {
            this.router.navigate([ErrorNavigationPage.NoAccess], {
              skipLocationChange: true
            });
          }

          return of(shouldActivate);
        })
      );
    }

    return of(true);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.canActivate(route, state);
  }
}
