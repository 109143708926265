import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy
} from '@angular/core';
import { InCbsNavigationService } from '@app/services/in-cbs-navigation.service';
import { Store } from '@ngrx/store';
import { InAppState } from '@app/app.types';
import { selectChamberUser } from '@app/store/reducers/live-sharing.reducer';
import { selectScheduleStatusId } from '@app/store/reducers/chat.reducer';
import {
  AccessOperation,
  ChamberBell,
  ILiveScheduleBI,
  ScheduleStatusId,
  IChamberUser,
  selectChamberBell
} from 'proceduralsystem-cbs-common-components';
import { ScheduleAccessService } from '@app/pages/schedule/schedule-access.service';
import { ScheduleChatStatuses } from './shared/components/chat/components/chat-button/chat-button.type';
import {
  ScheduleAccessOperation,
  IDailSchedule
} from './pages/schedule/schedule.types';
import { ToastNotificationsService } from 'proceduralsystem-clientcomponents';
import {
  selectChairDueToStart,
  selectClerkDueToStart
} from '@app/store/reducers/in-chamber.reducer';
import { TranslateService } from '@ngx-translate/core';
import { ScheduleDialogsService } from './pages/schedule/schedule-dialogs.service';
import { ScheduleService } from './pages/schedule/schedule.service';
import { Subject, of, Observable } from 'rxjs';
import {
  shareReplay,
  tap,
  map,
  take,
  switchMap,
  takeUntil,
  filter
} from 'rxjs/operators';
import { setScheduleStatusId } from './store/actions/chat.actions';
import { BellEndingService } from './services/bell-ending.service';
import * as BellTimerActions from '@app/store/actions/bell-timer.actions';

@Component({
  selector: 'app-root',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageComponent implements OnDestroy {
  private _destroy$ = new Subject<void>();

  isNavPaneCollapsed = false;
  isChatBtnVisible = false;
  accessOperations: ScheduleAccessOperation[] = [];
  chamberBell: ChamberBell | null;
  scheduleId: number;

  AccessOperation = AccessOperation;

  constructor(
    public readonly scheduleDialogsService: ScheduleDialogsService,
    private readonly scheduleService: ScheduleService,
    private readonly bellEndingService: BellEndingService,
    private readonly navigationService: InCbsNavigationService,
    private readonly accessService: ScheduleAccessService,
    private readonly toastService: ToastNotificationsService,
    private readonly translate: TranslateService,
    private readonly store: Store<InAppState>,
    private readonly cd: ChangeDetectorRef
  ) {
    this.handleStoreChanges();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  changeNavState(): void {
    this.isNavPaneCollapsed = !this.isNavPaneCollapsed;
  }

  openItem(data: { scheduleId: number; businessItemId: number }): void {
    this.navigationService.navigateToBIView(data.businessItemId);
  }

  onStartBusinessItem(data: ILiveScheduleBI) {
    this.scheduleService
      .startBusinessItem(data.scheduleId, data.businessItemId, data.instanceId)
      .pipe(take(1))
      .subscribe();
  }

  private fetchDailAndBellInfo(): Observable<ScheduleStatusId> {
    return this.scheduleService.fetchDailSchedule(true).pipe(
      shareReplay(1),
      tap((dail: IDailSchedule) => {
        this.chamberBell = (dail && dail.ongoingChamberBell) || null;
        this.bellEndingService.setBellTimer(dail);
      }),
      map((dail: IDailSchedule) => (dail ? dail.statusId : null)),
      tap((scheduleStatusId: ScheduleStatusId) => {
        this.store.dispatch(setScheduleStatusId({ scheduleStatusId }));
        this.cd.markForCheck();
      }),
      take(1)
    );
  }

  private handleStoreChanges(): void {
    this.store.select(selectScheduleStatusId).subscribe(scheduleStatusId => {
      this.isChatBtnVisible =
        !!scheduleStatusId && ScheduleChatStatuses.includes(scheduleStatusId);
      this.cd.markForCheck();
    });

    this.handleUserStoreChanges();
    this.handleDueDateStoreChanges();
    this.handleBellStoreChanges();
  }

  private handleUserStoreChanges(): void {
    this.store
      .select(selectChamberUser)
      .pipe(
        tap((chamberUser: IChamberUser) => {
          this.accessOperations =
            this.accessService.getAccessOperations(chamberUser);
          this.cd.markForCheck();
        }),
        switchMap(chamberUser => {
          if (!chamberUser) {
            return of(null);
          }

          return this.fetchDailAndBellInfo();
        }),
        takeUntil(this._destroy$)
      )
      .subscribe();
  }

  private handleDueDateStoreChanges(): void {
    this.store.select(selectClerkDueToStart).subscribe(clerkDueToStart => {
      if (clerkDueToStart && clerkDueToStart.length > 0) {
        this.toastService.addNotification({
          description: this.translate.instant(
            'TOAST_MESSAGE.TN9.CLERK_DUE_TO_START.MESSAGE',
            [clerkDueToStart.join(', ')]
          )
        });
      }
    });

    this.store.select(selectChairDueToStart).subscribe(chairDueToStart => {
      if (chairDueToStart && chairDueToStart.length > 0) {
        this.toastService.addNotification({
          description: this.translate.instant(
            'TOAST_MESSAGE.TN10.CHAIR_DUE_TO_START.MESSAGE',
            [chairDueToStart.join(', ')]
          )
        });
      }
    });
  }

  private handleBellStoreChanges(): void {
    this.store
      .select(selectChamberBell)
      .pipe(
        filter((nextBellData: ChamberBell) => {
          const isBellStarted =
            nextBellData !== null && this.chamberBell?.id !== nextBellData?.id;
          const isBellExtended =
            this.chamberBell?.id === nextBellData?.id &&
            nextBellData?.durationPlanned > this.chamberBell?.durationPlanned;
          const isBellStopped = this.chamberBell && nextBellData === null;
          this.chamberBell = { ...nextBellData };

          if (isBellStopped) {
            this.store.dispatch(BellTimerActions.stopBellTimer());
          }

          return isBellStarted || isBellExtended;
        }),
        switchMap(() => {
          return this.scheduleService.fetchDailSchedule(true).pipe(
            take(1),
            tap((dail: IDailSchedule) => {
              this.scheduleId = dail?.id;
              this.bellEndingService.setBellTimer(dail);
            })
          );
        }),
        takeUntil(this._destroy$)
      )
      .subscribe();
  }
}
