<div class="modal-dialog ta_s_{{dialogName}}">
  <div class="modal-header">
    <h2 mat-dialog-title>
      {{ data.title | translate }}      
    </h2>
    <mat-icon [mat-dialog-close]="null" class="mat-close-icon" aria-label="close icon">
      close
    </mat-icon>
  </div>
  <mat-dialog-content class="modal-body">
    <div class="description">
      {{ data.message | translate }}
    </div>

    <form [formGroup]="scheduleForm">
      <mat-radio-group class="cancel-business-item-radio-group" formControlName="selectedBusinessItem">
        <ng-container *ngFor="let businessItem of businessItems; let i = index">
          <mat-radio-button *ngIf="businessItem" class="radio-button-mat ta_s_{{dialogName}}_option_{{i}}" [value]="businessItem">
            <ng-container *ngIf="i === 0">{{'EXTEND_BELL_DIALOG.RESUME.LABEL' | translate}}: </ng-container>
            <ng-container *ngIf="i === 1">{{'COMMON.START' | translate}}: </ng-container>
            <span class="radio-title" [innerHTML]="(businessItem?.workItemTitle || businessItem.title || businessItem.businessItemTitle) | safehtml"></span>
          </mat-radio-button>
        </ng-container>
      </mat-radio-group>

      <mat-form-field
          *ngIf="scheduleForm.get('selectedBusinessItem').value === businessItems[2]"
          appearance="fill"
          class="cancel-dialog-input-field ta_s_stopQuorumDialog_duration">
            <mat-label>{{ 'EXTEND_BELL_DIALOG.DURATION.LABEL' | translate }}</mat-label>
            <input
              matInput
              formControlName="duration"
              autoComplete="off"
              maxlength="2"
              [disabled]="scheduleForm.get('selectedBusinessItem').value !== businessItems[2]">
      </mat-form-field>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions class="dialog-buttons">
    <button mat-button class="btn-basic ta_cancel" color="primary" [mat-dialog-close]="null"
      type="button">
      {{ 'DIALOG.CANCEL.BUTTON' | translate | uppercase }}
    </button>
    <button mat-button class="btn-basic ta_confirm" color="primary" [mat-dialog-close]="scheduleForm.getRawValue()" type="button">
      {{ (data?.confirmButton?.name || 'COMMON.CONFIRM' ) | translate | uppercase}} 
    </button>
  </mat-dialog-actions>
</div>
