import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  ILiveView,
  selectLiveViews
} from '@app/store/reducers/live-sharing.reducer';
import { InAppState, NavigationPage } from '@app/app.types';
import { BadgeUtils, UserRole } from 'proceduralsystem-cbs-common-components';

@Component({
  selector: 'oir-active-users',
  templateUrl: './active-users.component.html',
  styleUrls: ['./active-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActiveUsersComponent {
  activeUsers$: Observable<ILiveView[]> = this.store.select(selectLiveViews);

  BadgeUtils = BadgeUtils;
  NavigationPage = NavigationPage;
  UserRole = UserRole;

  constructor(private readonly store: Store<InAppState>) {}
}
