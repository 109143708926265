import { AppState, ChamberBell } from 'proceduralsystem-cbs-common-components';
import { ChatState } from '@app/store/reducers/chat.reducer';
import { LiveSharingState } from '@app/store/reducers/live-sharing.reducer';
import { IBusinessItem } from './pages/business-item-view/business-item-view.types';

export const SecondsInMin = 60;
export const SecondsBase = 1000;

export interface InAppState extends AppState {
  chat: ChatState;
  liveSharing: LiveSharingState;
}

export interface ISpeakerParams {
  actionId: number;
  businessItemId: number;
  itemId: number;
  stateId: number;
}

export interface IBellTimer {
  startTime: Date | string;
  durationPlanned: number;
  scheduleId: number;
  businessItems: IBusinessItem[];
  ongoingChamberBell: ChamberBell;
}

export enum NavigationPage {
  Schedule = 'schedule',
  BusinessItemView = 'business-item',
  ReferenceNotes = 'reference-notes',
  SOSView = 'sos-view',
  DivisionView = 'division'
}

export enum PageTitle {
  Schedule = 'SCHEDULE.TITLE',
  BusinessItemView = 'SCHEDULE.TITLE',
  ReferenceNotes = 'REFERENCE_NOTES.TITLE',
  SOSView = 'SOS_VIEW.TITLE',
  DivisionView = 'DIVISION_VIEW.TITLE'
}

export enum EndingBellTypes {
  Resume = 1,
  Start,
  Extend
}
