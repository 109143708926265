import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import {
  Navigation,
  NavigationService,
  ApplicationEntry
} from 'proceduralsystem-clientcomponents';

@Injectable({ providedIn: 'root' })
export class InChamberSideNavigationService {
  constructor(private navigationService: NavigationService) {}

  fetchUserApplications(): Observable<ApplicationEntry[]> {
    return this.navigationService.fetchUserApplications().pipe(shareReplay(1));
  }

  getNavigationModel(): Observable<Navigation> {
    return this.navigationService.navModel$;
  }
}
