import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of, switchMap } from 'rxjs';
import { catchError, tap, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { setChamberUser } from '@app/store/actions/live-sharing.actions';
import { selectChamberUser } from '@app/store/reducers/live-sharing.reducer';
import {
  ConfigurationService,
  OirAuthService,
  OirDisplayError,
  OirHttpService,
  ToastNotificationsService
} from 'proceduralsystem-clientcomponents';
import {
  AppConfig,
  CookieItem,
  IChamberUser,
  UserRole
} from 'proceduralsystem-cbs-common-components';
import { InAppState, SecondsBase } from '@app/app.types';
import { SwitchUserComponent } from '@shared/components/header-bar/components/dialog/switch-user/switch-user.component';

@Injectable({ providedIn: 'root' })
export class InCbsUserService {
  constructor(
    private oirHttp: OirHttpService,
    private config: ConfigurationService<AppConfig>,
    private authService: OirAuthService,
    private toastService: ToastNotificationsService,
    private translate: TranslateService,
    private matDialog: MatDialog,
    private store: Store<InAppState>
  ) {}

  openUserClockInDialog(): void {
    const dialogRef = this.matDialog.open(SwitchUserComponent, {
      hasBackdrop: true,
      disableClose: true,
      panelClass: 'dialog-mat'
    });
    dialogRef.componentInstance.type = 'clock-in';
    dialogRef.afterClosed().subscribe(data => {
      if (data !== 'COMMON.CANCEL') {
        this.clockInUser({
          userObjectId: data.clerk.userObjectId,
          name: data.clerk.fullName,
          role: UserRole.ChamberClerk
        }).subscribe();
      }
    });
  }

  openUserClockOutDialog(): void {
    this.store
      .select(selectChamberUser)
      .pipe(take(1))
      .subscribe(chamberUser => {
        if (chamberUser) {
          const dialogRef = this.matDialog.open(SwitchUserComponent, {
            hasBackdrop: true,
            disableClose: true,
            panelClass: 'dialog-mat'
          });
          dialogRef.componentInstance.type = 'clock-out';
          dialogRef.componentInstance.form.get('clerk').patchValue(
            {
              fullName: chamberUser.name,
              userObjectId: chamberUser.userObjectId
            },
            { emitEvent: false, onlySelf: true }
          );
          dialogRef.componentInstance.form.markAsDirty();
          dialogRef.afterClosed().subscribe(data => {
            if (data !== 'COMMON.CANCEL') {
              this.clockOutUser(true).subscribe();
            }
          });
        }
      });
  }

  checkSession(): Observable<boolean> {
    if (this.authService.hasRole(UserRole.CeannComhairle)) {
      this.store.dispatch(
        setChamberUser({
          chamberUser: {
            userObjectId: this.authService.getUserId(),
            name: this.authService.getFullname(),
            role: UserRole.CeannComhairle
          }
        })
      );
    }

    const currentChamberUser = JSON.parse(
      this.config.getCookie(CookieItem.chamberUser)
    );

    if (!currentChamberUser) {
      return of(false);
    }

    this.store.dispatch(setChamberUser({ chamberUser: currentChamberUser }));

    return this.oirHttp
      .get<boolean>({
        path: '/api/clerk/checkSession',
        query: {
          userObjectId: currentChamberUser.userObjectId
        }
      })
      .pipe(
        tap(result => {
          if (!result) {
            this.clockOutUser().subscribe();
          }
        })
      );
  }

  clockInUser(chamberUser: IChamberUser): Observable<boolean> {
    return this.oirHttp
      .post<boolean>({
        path: '/api/clerk/clock-in',
        body: {
          chamberUser: {
            userObjectId: chamberUser.userObjectId,
            name: chamberUser.name,
            role: UserRole.ChamberClerk
          }
        }
      })
      .pipe(
        tap(() => {
          if (chamberUser) {
            this.toastService.addNotification({
              description: this.translate.instant('CLOCK-IN.TOAST_MESSAGE', [
                chamberUser.name.split(' ')[0]
              ])
            });
          }

          this.config.setCookie(
            CookieItem.chamberUser,
            JSON.stringify(chamberUser),
            1
          );

          setTimeout(() => {
            window.location.replace('/');
          }, SecondsBase);
        }),
        catchError((error: OirDisplayError) => {
          this.toastService.addNotification({
            title: 'COMMON.REQUESTERROR',
            error: error.detail
          });

          this.clockOutUser().subscribe();

          return of(true);
        })
      );
  }

  clockOutUser(isManual = false): Observable<boolean> {
    if (this.authService.hasRole(UserRole.CeannComhairle)) {
      return of(false);
    }

    return this.store.select(selectChamberUser).pipe(
      take(1),
      switchMap(chamberUser => {
        if (!chamberUser) {
          return this.postClockOut();
        }

        return this.oirHttp
          .post<boolean>({
            path: '/api/clerk/clock-out',
            body: {
              chamberUser: {
                userObjectId: chamberUser.userObjectId
              }
            }
          })
          .pipe(
            tap(result => {
              if (result) {
                this.toastService.addNotification({
                  description: isManual
                    ? 'CLOCK-OUT.SUCCESS_TOAST_MESSAGE'
                    : 'CLOCK-OUT.SESSION_EXPIRED_TOAST_MESSAGE'
                });
              }
              return this.postClockOut();
            }),
            catchError(() => this.postClockOut())
          );
      })
    );
  }

  postClockOut(): Observable<boolean> {
    this.store.dispatch(setChamberUser({ chamberUser: null }));
    this.config.eraseCookie(CookieItem.chamberUser);

    setTimeout(() => {
      window.location.replace('/');
    }, SecondsBase);

    return of(true);
  }
}
