<div class="modal-dialog suspension-dialog ta_s_suspensionDialog">
	<div class="modal-header">
		<h2 mat-dialog-title>
			{{ title | translate }}
		</h2>
		<mat-icon [mat-dialog-close]="null" class="mat-close-icon" aria-label="close icon">
			close
		</mat-icon>
	</div>
	<mat-dialog-content class="modal-body">
		<div class="suspension-dialog-message">
			{{ (showNextPage && status !== ScheduleStatusId.Suspended ? messageAlt : message) | translate }}
		</div>
		<form [formGroup]="form">
			<mat-radio-group class="suspension-type ta_s_suspensionDialog_type" formControlName="type" *ngIf="!showNextPage">
				<ng-container *ngFor="let option of OPTIONS; let i = index">
					<div *ngIf="i === 1" class="ta_s_suspensionDialog_optionSeparator option-separator">
						<mat-divider></mat-divider><span class="separator-or">{{ "COMMON.OR" | translate }}</span><mat-divider></mat-divider>
					</div>
					<mat-radio-button id="type_{{i}}" class="radio-button-mat" [value]="option.value">
						<p>{{ option.title | translate }}</p>
					</mat-radio-button>
				</ng-container>
			</mat-radio-group>
			<ng-container *ngIf="showNextPage">
				<mat-radio-group formControlName="suspensionType">
					<mat-radio-button class="radio-button-mat next-page" [value]="SuspensionType.Duration" color="primary">
						{{ 'SUSPENSION_DIALOG.TYPES.DURATION' | translate }}
					</mat-radio-button>
					<mat-radio-button class="radio-button-mat next-page" [value]="SuspensionType.Until" color="primary">
						{{ 'SUSPENSION_DIALOG.TYPES.UNTIL' | translate }}
					</mat-radio-button>
				</mat-radio-group>
				<mat-form-field *ngIf="form.get('suspensionType').value === SuspensionType.Duration" appearance="fill"
					class="input-field to-be-process-duration">
					<mat-label>{{ 'SUSPENSION_DIALOG.DURATION.LABEL' | translate }}</mat-label>
					<input matInput [textMask]="{mask: durationMask, guide: false}" [placeholder]="'SUSPENSION_DIALOG.DURATION.PLACEHOLDER' | translate" formControlName="duration" min="1" max="999" autocomplete="off">
					<mat-error *ngIf="form.get('duration').hasError('required')">
						{{ 'MANDATORY_FIELD.REQUIRED' | translate }}
					</mat-error>
				</mat-form-field>
				<mat-form-field *ngIf="form.get('suspensionType').value === SuspensionType.Until" appearance="fill"
					class="input-field to-be-process-until">
					<mat-label>{{ 'SUSPENSION_DIALOG.UNTIL.LABEL' | translate }}</mat-label>
					<input matInput [textMask]="{mask: untilTimeMask, guide: false}" [placeholder]="'SUSPENSION_DIALOG.UNTIL.PLACEHOLDER' | translate" formControlName="until" autocomplete="off">
					<mat-error *ngIf="form.get('until').hasError('required')">
						{{ 'MANDATORY_FIELD.REQUIRED' | translate }}
					</mat-error>
					<mat-error *ngIf="form.get('until').hasError('pattern')">
						{{ 'SUSPENSION_DIALOG.UNTIL.INVALID_TIME_FORMAT.MESSAGE' | translate }}
					</mat-error>
					<mat-error *ngIf="form.get('until').hasError('futureTime')">
						{{ 'SUSPENSION_DIALOG.UNTIL.INVALID_FUTURE_TIME.MESSAGE' | translate }}
					</mat-error>
				</mat-form-field>
			</ng-container>
		</form>
	</mat-dialog-content>
	<div [class.modal-footer]="status !== ScheduleStatusId.Suspended">
    <div class="page-navigation" *ngIf="status !== ScheduleStatusId.Suspended">
      <button
        *ngIf="showNextPage"
        (click)="resetForm()"
        class="icon-button page-back ta_s_suspensionDialog_pageBackBttn">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div class="page-dots ta_s_suspensionDialog_pageNavigation">
        <div class="ta_s_suspensionDialog_page_1" [class.active]="!showNextPage"></div>
        <div class="ta_s_suspensionDialog_page_2" [class.active]="showNextPage"></div>
      </div>
    </div>
		<mat-dialog-actions class="dialog-buttons">
			<button mat-button 
							class="btn-basic ta_cancel" 
							color="primary" 
							[mat-dialog-close]="null" 
							type="button">
								{{ 'SUSPENSION_DIALOG.BUTTON.CANCEL' | translate | uppercase }}
						</button>
			<button mat-button
							*ngIf="status === ScheduleStatusId.Suspended || showNextPage"
							class="btn-basic ta_confirm"
							color="primary"
							type="button"
							[disabled]="form.get('suspensionType').value === SuspensionType.Duration ? form.get('duration').errors : form.get('until').errors"
							[mat-dialog-close]="getFormValues()">
								{{ confirmButton | translate | uppercase }}
			</button>
			<button mat-button
							*ngIf="!showNextPage && (status !== ScheduleStatusId.Suspended)"
							class="btn-basic ta_confirm"
							color="primary"
							type="button"
							[disabled]="!form.get('type').value"
							(click)="onNextPageClick()">
								{{ 'SUSPENSION_DIALOG.PROCEED.BUTTON' | translate | uppercase }}
			</button>
		</mat-dialog-actions>
	</div>
</div>
