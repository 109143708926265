import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectChamberUser } from '@app/store/reducers/live-sharing.reducer';
import { selectChatMessages } from '@app/store/reducers/chat.reducer';
import {
  UserRole,
  CBSValidators
} from 'proceduralsystem-cbs-common-components';
import { InAppState } from '@app/app.types';
import { IChatMessage } from '@shared/components/chat/chat.types';
import { ChatService } from '@shared/components/chat/chat.service';

@Component({
  selector: 'oir-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatComponent implements AfterContentChecked, OnDestroy {
  @ViewChild('chatBody', { static: true })
  private chatBodyScrollContainer: ElementRef;
  private destroy$ = new Subject<void>();

  MESSAGE_MAX_LENGTH = 1500;

  form = new FormGroup({
    messageBox: new FormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(this.MESSAGE_MAX_LENGTH),
        CBSValidators.NotEmpty
      ])
    )
  });
  userFullName: string;
  messages$: Observable<IChatMessage[]> = this.store.select(selectChatMessages);
  quickReplies$: Observable<string[]> = this.chatService.fetchQuickReplies();
  disableScrollDown = false;

  constructor(
    private chatService: ChatService,
    private store: Store<InAppState>,
    private cd: ChangeDetectorRef
  ) {
    this.store
      .select(selectChamberUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe(v => {
        this.userFullName =
          v.role === UserRole.CeannComhairle ? 'CHAT.CHAIR.USER_NAME' : v.name;
      });
  }

  // This method does check if happened action scroll top,
  // if yes then when the user types a message not happening
  // scrolling bottom
  onScroll() {
    const element = this.chatBodyScrollContainer.nativeElement;
    const atBottom =
      element.scrollHeight - element.scrollTop === element.clientHeight;
    this.disableScrollDown = !atBottom;
  }

  scrollToBottom(disableScrollDown = false) {
    if (disableScrollDown) {
      return;
    }
    this.chatBodyScrollContainer.nativeElement.scrollTop =
      this.chatBodyScrollContainer.nativeElement.scrollHeight;
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  ngAfterContentChecked(): void {
    this.scrollToBottom(this.disableScrollDown);
  }

  onSend(): void {
    this.sendMessage(this.form.get('messageBox').value);
  }

  onQuickReplies(quickReply: string) {
    this.sendMessage(quickReply);
  }

  private sendMessage(message: string) {
    this.disableScrollDown = false;
    this.chatService
      .sendMessage({
        message,
        userFullName: this.userFullName
      })
      .subscribe(result => {
        if (result) {
          this.form.reset();
          this.scrollToBottom();
        }
      });
  }
}
