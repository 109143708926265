<div class="header-menu-bar">
  <div class="left">
    <ng-container *ngIf="accessOperations.length > 0 && navigationService.backOptions$ | async; let options">
      <button mat-button *ngIf="options && options.path" (click)="onBackButtonClick(options)">
        <mat-icon aria-hidden="true">chevron_left</mat-icon>
        <span>{{ 'COMMON.BACK' | translate | uppercase }}</span>
      </button>
    </ng-container>
  </div>
  <div class="right">
    <oir-toast-notifications></oir-toast-notifications>
    <div class="ta_activeUserButton active-user-icon" (click)="openActiveUsers()"><div><mat-icon>groups</mat-icon></div></div>
    <ng-container *ngIf="accessOperations.length > 0">
      <oir-notifications-info></oir-notifications-info>
    </ng-container>
    <oir-chamber-user-info (clockOut)="clockOutUser()" (clockIn)="clockInUser()"></oir-chamber-user-info>
    <oir-language-toggle class="ta_language_panel"></oir-language-toggle>
  </div>
</div>
