import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { NavigationPage, PageTitle } from '@app/app.types';
import { ILiveView } from '@app/store/reducers/live-sharing.reducer';
import { TranslateService } from '@ngx-translate/core';
import { ILiveViewData } from '@app/pages/business-item-view/business-item-view.types';
import { LanguageType } from 'proceduralsystem-cbs-common-components';

@Component({
  selector: 'oir-active-user-path-message',
  templateUrl: './active-user-path-message.component.html',
  styleUrls: ['./active-user-path-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActiveUserPathMessageComponent {
  titlePath: string;
  body: string;
  subPath: string;

  @Input() set data(value: ILiveView) {
    if (!value) {
      return;
    }

    switch (value.page) {
      case NavigationPage.Schedule:
        this.titlePath = this.translate.instant(value.data.pageName);
        this.body = value.data.tabName;
        break;
      case NavigationPage.ReferenceNotes:
        this.body = value.data.pageName;
        break;
      case NavigationPage.BusinessItemView:
        this.processBusinessItemView(value.data);
        break;
      case NavigationPage.SOSView:
        this.processBusinessItemView(value.data);
        break;
      default:
        this.body = value.page;
        break;
    }
  }

  constructor(private translate: TranslateService) {}

  private processBusinessItemView(data: ILiveViewData): void {
    if (data) {
      const biInfoName =
        this.translate.currentLang == LanguageType.en
          ? data.businessItemInfo.titleEng
          : data.businessItemInfo.titleGle;
      const biInfoTitle =
        this.translate.currentLang == LanguageType.en
          ? data.businessItemInfo.workItemTitleEng
          : data.businessItemInfo.workItemTitleGle;
      const tabName: string = data.tabName ? this.translate.instant(data.tabName) : null;
      const subTabName: string = data.subTabName ? this.translate.instant(data.subTabName) : null;

      const schedulePathPrefix = [this.translate.instant(PageTitle.Schedule), this.translate.instant('SCHEDULE.TAB.DAIL')]
      this.titlePath = (
        biInfoTitle
          ? [...schedulePathPrefix , biInfoName]
          : [...schedulePathPrefix]
      ).join(' > ');

      this.body = biInfoTitle ? biInfoTitle : biInfoName;

      this.subPath = (subTabName ? [tabName, subTabName] : [tabName]).join(
        ' > '
      );
    }
  }
}
