import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import * as BellTimerActions from '@app/store/actions/bell-timer.actions';
import { Store } from '@ngrx/store';
import {
  InAppState,
  EndingBellTypes,
  IBellTimer,
  SecondsInMin
} from '@app/app.types';
import {
  ChamberBellSubTypeId,
  ILiveScheduleBI,
  StopBellActionId
} from 'proceduralsystem-cbs-common-components';
import { ScheduleDialogsService } from '@app/pages/schedule/schedule-dialogs.service';
import { MatDialogRef } from '@angular/material/dialog';
import { QuorumEndingDialogModule } from '@app/shared/dialogs/quorum-ending-dialog/quorum-ending-dialog.module';
import { IDailSchedule } from '@app/pages/schedule/schedule.types';
import { BellService } from './bell.service';
import { ScheduleService } from '@app/pages/schedule/schedule.service';
import { findLast } from 'lodash-es';

interface DialogData {
  selectedBusinessItem: ILiveScheduleBI;
  duration: number;
  choice: EndingBellTypes;
}

@Injectable({
  providedIn: 'root'
})
export class BellEndingService {
  constructor(
    private readonly store: Store<InAppState>,
    private readonly scheduleDialogService: ScheduleDialogsService,
    private readonly bellService: BellService,
    private readonly scheduleService: ScheduleService
  ) {}

  private _dialogRef: MatDialogRef<QuorumEndingDialogModule, any> | null;

  setBellTimer(v: IDailSchedule): void {
    if (
      v.ongoingChamberBell &&
      (v.ongoingChamberBell.subTypeId ===
        ChamberBellSubTypeId.QuorumBusinessItem ||
        v.ongoingChamberBell.subTypeId === ChamberBellSubTypeId.QuorumSchedule)
    ) {
      this.store.dispatch(
        BellTimerActions.startBellTimer({
          startTime: v.ongoingChamberBell.startTime,
          durationPlanned: v.ongoingChamberBell.durationPlanned,
          scheduleId: v.id,
          businessItems: [
            {
              ...(this._findItemById(
                v.scheduleItems,
                v.ongoingBusinessItem.businessItemId
              ).businessItem || null),
              type: v.ongoingChamberBell.subTypeId
            },
            this._findNextItemWithBusinessItem(
              v.scheduleItems,
              v.ongoingBusinessItem.businessItemId
            ) || null
          ],
          ongoingChamberBell: { ...v.ongoingChamberBell }
        })
      );
    }
  }

  get isDialogOpen(): boolean {
    return !!this._dialogRef;
  }

  openDialog(action: IBellTimer): void {
    if (!this._dialogRef) {
      this._dialogRef = this.scheduleDialogService.getQuorumEndingDialog(
        'ENDING_SCHEDULE_QUORUM_MODAL.TITLE',
        'ENDING_SCHEDULE_QUORUM_MODAL.MESSAGE',
        { action }
      );
      this._dialogRef
        .beforeClosed()
        .pipe(take(1))
        .subscribe((result: DialogData) => {
          if (result) {
            this._onDialogAction(result, action);
          } else {
            this.closeDialog();
          }
        });
    }
  }

  closeDialog(): void {
    if (this._dialogRef) {
      this._dialogRef.close();
      this._dialogRef = null;
    }
  }

  stopBell(scheduleId: number): void {
    this.bellService.stopBell(scheduleId, StopBellActionId.Cancel).subscribe();
  }

  private _onDialogAction(result: DialogData, bell: IBellTimer): void {
    switch (result.choice) {
      case EndingBellTypes.Resume:
      case EndingBellTypes.Start:
        this._startOrResumeBI({
          ...result.selectedBusinessItem,
          scheduleId: bell.scheduleId
        });
        break;
      case EndingBellTypes.Extend:
        this.bellService
          .extendQuorumBell(
            bell.scheduleId,
            bell.ongoingChamberBell.id,
            result.duration * SecondsInMin
          )
          .subscribe(v => this._endTimer(v > -1));
        break;
      default:
        this._endTimer(true);
        break;
    }
  }

  private _endTimer(v: boolean): void {
    if (v) {
      this.store.dispatch(BellTimerActions.stopBellTimer());
      this.closeDialog();
    }
  }

  private _startOrResumeBI(data: ILiveScheduleBI): void {
    this.scheduleService
      .startBusinessItem(data.scheduleId, data.id, data.instanceId)
      .pipe(take(1))
      .subscribe(v => this._endTimer(v > -1));
  }

  private _findItemById(items: any[], id: number): any {
    return items.find(
      item =>
        item.id === id || (item.businessItem && item.businessItem.id === id)
    );
  }

  private _findNextItemWithBusinessItem(items: any[], currentId: number): any {
    const currentItem = findLast(
      items,
      item =>
        item.id === currentId ||
        (item.businessItem && item.businessItem.id === currentId)
    );
    const currentIndex = items.indexOf(currentItem);

    if (currentIndex === -1 || currentIndex === items.length - 1) {
      return null;
    }

    for (let i = currentIndex + 1; i < items.length; i++) {
      if (items[i].businessItem != null) {
        return items[i].businessItem;
      }
    }

    return null;
  }
}
