import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import * as BellTimerActions from '@app/store/actions/bell-timer.actions';
import { interval } from 'rxjs';
import moment from 'moment';
import { BellEndingService } from '@app/services/bell-ending.service';
import { IBellTimer, SecondsBase } from '@app/app.types';

const QUORUM_BELL_BEFORE_END_SEC = 15;

@Injectable()
export class BellTimerEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly bellEndingService: BellEndingService
  ) {}

  startBellTimer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BellTimerActions.startBellTimer),
      switchMap((action: IBellTimer) => {
        const startTimeUnix = moment(action.startTime).unix();
        const durationPlanned = action.durationPlanned;
        let dialogOpenedOnce = false;

        return interval(SecondsBase).pipe(
          takeUntil(this.actions$.pipe(ofType(BellTimerActions.stopBellTimer))),
          tap(() => {
            const currentTimeUnix = moment().unix();
            const timeLeft = startTimeUnix + durationPlanned - currentTimeUnix;

            if (
              timeLeft <= QUORUM_BELL_BEFORE_END_SEC &&
              timeLeft > 0 &&
              !this.bellEndingService.isDialogOpen &&
              !dialogOpenedOnce
            ) {
              dialogOpenedOnce = true;
              this.bellEndingService.openDialog(action);
            }

            // Stop timer if end time is reached
            if (
              durationPlanned !== null &&
              currentTimeUnix >= startTimeUnix + durationPlanned
            ) {
              this.store.dispatch(BellTimerActions.stopBellTimer());
            }
          }),
          map(() =>
            BellTimerActions.updateBellTimer({ currentTime: moment().unix() })
          )
        );
      })
    )
  );
}
