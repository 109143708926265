<div class="modal-dialog extend-bell-dialog">
  <div class="modal-header">
    <h2 mat-dialog-title>
      {{ data.title | translate}}
    </h2>
    <mat-icon [mat-dialog-close]="null" class="mat-close-icon" aria-label="close icon">
      close
    </mat-icon>
  </div>
  <mat-dialog-content class="modal-body">
    <div class="description">
      {{ data.message | translate}}
    </div>
    <mat-form-field appearance="fill" class="input-field extend-bell-input">
      <mat-label>{{ 'EXTEND_BELL_DIALOG.DURATION.LABEL' | translate }}</mat-label>
      <input [formControl]="duration" autoComplete="off" matInput type="number" class="ta_s_extendDuration">
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions class="dialog-buttons">
    <button mat-button class="btn-basic ta_cancel" color="primary" [mat-dialog-close]="null"
      type="button">
      {{ 'DIALOG.CANCEL.BUTTON' | translate | uppercase }}
    </button>
    <button mat-button class="btn-basic ta_confirm" color="primary" [disabled]="duration.invalid" [mat-dialog-close]="duration.value"
      type="button">
      {{ 'EXTEND_BELL_DIALOG.BUTTON.CONFIRM' | translate | uppercase }}
    </button>
  </mat-dialog-actions>
</div>
