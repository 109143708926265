<div class="modal-dialog adjourn-dialog">
	<div class="modal-header">
		<h2 mat-dialog-title>
			{{ data.title | translate }}
		</h2>
		<mat-icon [mat-dialog-close]="null" class="mat-close-icon" aria-label="close icon">
			close
		</mat-icon>
	</div>
	<mat-dialog-content class="modal-body">
		<div class="adjourn-dialog-message">
			{{ data.message | translate }}
		</div>
		<ng-container *ngIf="data.context">
			<mat-checkbox class="checkbox-mat subtitle-secondary" [checked]="isConfirmed" [(ngModel)]="isConfirmed">
				{{ data.context | translate }}
			</mat-checkbox>
		</ng-container>
	</mat-dialog-content>
	<mat-dialog-actions class="dialog-buttons">
		<button mat-button
						class="btn-basic ta_cancel"
						color="primary"
						[mat-dialog-close]="null"
						type="button">
						{{ data.cancelButton.name | translate | uppercase }}
					</button>
		<button mat-button
						class="btn-basic ta_confirm"
						color="primary"
						[disabled]="data.context ? !isConfirmed : false"
						[mat-dialog-close]="true" type="button">
						{{ data.confirmButton.name | translate | uppercase}}
					</button>
	</mat-dialog-actions>
</div>
