import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatDialogConfig
} from '@angular/material/dialog';
import { DialogPosition } from 'proceduralsystem-clientcomponents';
import {
  ChamberBell,
  ChamberBellSubTypeId,
  ChamberBellTypeId,
  IBellTypes,
  ILiveScheduleBI,
  MoreOption,
  MoreOptionId,
  StopBellActionId,
  selectChamberBell,
  selectScheduleBI,
  BusinessItemTypeId
} from 'proceduralsystem-cbs-common-components';
import { BellService } from '../../services/bell.service';
import {
  DivisionConfirmDialogData,
  ICallDivisionDialogData
} from '@app/shared/dialogs/call-division-bell-dialog/call-division-bell-dialog.types';
import { IBusinessItem } from './schedule.types';
import { Store } from '@ngrx/store';
import { selectReadyToStartBusinessItem } from '@app/store/reducers/in-chamber.reducer';
import * as BellTimerActions from '@app/store/actions/bell-timer.actions';
import { SecondsInMin } from '@app/app.types';

// Dialog Components
import { ConfirmationDialogComponent } from '@app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { SuspensionDialogComponent } from '@app/shared/dialogs/suspension-dialog/suspension-dialog.component';
import { ExtendBellDialogComponent } from '@app/shared/dialogs/extend-bell-dialog/extend-bell-dialog.component';
import { CancelScheduleDivDialogComponent } from '@app/shared/dialogs/cancel-schedule-div-dialog/cancel-schedule-div-dialog.component';
import {
  Observable,
  combineLatestWith,
  distinctUntilChanged,
  filter,
  iif,
  map,
  of,
  switchMap,
  take,
  tap
} from 'rxjs';
import { CallDivisionBellDialogComponent } from '@app/shared/dialogs/call-division-bell-dialog/call-division-bell-dialog.component';
import { CallQuorumBellDialogComponent } from '@app/shared/dialogs/call-quorum-bell-dialog/call-quorum-bell-dialog.component';
import { QuorumEndingDialogComponent } from '@app/shared/dialogs/quorum-ending-dialog/quorum-ending-dialog.component';
import {
  MIN_QUORUM_DURATION,
  QuorumConfirmDialogData
} from '@app/shared/dialogs/call-quorum-bell-dialog/call-quorum-bell-dialog.types';

@Injectable({
  providedIn: 'root'
})
export class ScheduleDialogsService {
  private readonly dialogDefaultOptions: MatDialogConfig<any> = {
    hasBackdrop: true,
    position: DialogPosition.CENTER,
    panelClass: 'dialog-mat'
  };
  private bellActionDialogRef: MatDialogRef<any> = null;

  constructor(
    private readonly dialog: MatDialog,
    private readonly bellService: BellService,
    private readonly store: Store
  ) {
    this.handleBellChanges();
  }

  getAdjournDialogRef(): MatDialogRef<ConfirmationDialogComponent, any> {
    return this.dialog.open(ConfirmationDialogComponent, {
      ...this.dialogDefaultOptions,
      data: {
        title: 'ADJOURN_DIALOG.TITLE',
        message: 'ADJOURN_DIALOG.MESSAGE',
        context: 'ADJOURN_DIALOG.SITTING_CONFIRM_LABEL',
        cancelButton: { name: 'COMMON.CANCEL' },
        confirmButton: { name: 'ADJOURN_DIALOG.BUTTON.CONFIRM' }
      }
    });
  }

  getResumeSittingDialogRef(): MatDialogRef<ConfirmationDialogComponent, any> {
    return this.dialog.open(ConfirmationDialogComponent, {
      ...this.dialogDefaultOptions,
      data: {
        title: 'RESUME_SITTING_DIALOG.TITLE',
        message: 'RESUME_SITTING_DIALOG.MESSAGE',
        context: 'RESUME_SITTING_DIALOG.SITTING_CONFIRM_LABEL',
        cancelButton: { name: 'COMMON.CANCEL' },
        confirmButton: { name: 'RESUME_SITTING_DIALOG.BUTTON.CONFIRM' }
      }
    });
  }

  getSuspendDialogRef(): MatDialogRef<SuspensionDialogComponent, any> {
    return this.dialog.open(
      SuspensionDialogComponent,
      this.dialogDefaultOptions
    );
  }

  getCallDivisionDialogRef(
    dialogData: ICallDivisionDialogData
  ): Observable<DivisionConfirmDialogData> {
    const { context } = dialogData;
    this.bellActionDialogRef = this.dialog.open(
      CallDivisionBellDialogComponent,
      {
        ...this.dialogDefaultOptions,
        data: {
          title: 'CALL_DIVISION_DIALOG.TITLE',
          message: 'CALL_DIVISION_DIALOG.LABEL',
          cancelButton: { name: 'COMMON.CANCEL' },
          confirmButton: { name: 'COMMON.START' },
          context
        }
      }
    );

    return this.bellActionDialogRef
      .afterClosed()
      .pipe(tap(() => this.clearBellDialogRef()));
  }

  openMoreOptionDialog(
    value: {
      businessItem: ILiveScheduleBI;
      option: MoreOption;
    },
    chamberBell: ChamberBell,
    scheduleId?: number
  ): void {
    if (value.option.id === MoreOptionId.ExtendBell) {
      const isQuorum = chamberBell.typeId === ChamberBellTypeId.Quorum;
      const title = isQuorum
        ? 'EXTEND_QUORUM_DIALOG.TITLE'
        : 'EXTEND_BELL_DIALOG.TITLE';
      const message = isQuorum
        ? 'EXTEND_QUORUM_DIALOG.MESSAGE'
        : 'EXTEND_BELL_DIALOG.MESSAGE';
      this.bellActionDialogRef = this.getExtendBellQuorumDialogRef(
        title,
        message
      );
      this.bellActionDialogRef
        .afterClosed()
        .pipe(
          tap(() => (this.bellActionDialogRef = null)),
          take(1),
          switchMap((durationMin: number) => {
            if (!durationMin) {
              return of(false);
            }

            if (isQuorum) {
              return this.bellService
                .extendQuorumBell(
                  value.businessItem.scheduleId || scheduleId,
                  chamberBell.id,
                  durationMin * SecondsInMin
                )
                .pipe(
                  tap(result => {
                    if (result > -1) {
                      this.store.dispatch(BellTimerActions.stopBellTimer());
                    }
                  })
                );
            }

            return this.bellService.extendDivBell(
              value.businessItem.scheduleId || scheduleId,
              chamberBell.id,
              durationMin * SecondsInMin
            );
          })
        )
        .subscribe();
    } else if (value.option.id === MoreOptionId.CancelDivision) {
      this.cancelBell(value, chamberBell, scheduleId);
    }
  }

  onBellActionClick(value: ILiveScheduleBI | null, scheduleId?: number): void {
    this.store
      .select(selectChamberBell)
      .pipe(
        combineLatestWith(this.store.select(selectScheduleBI)),
        take(1),
        switchMap(([bell, scheduleBi]) => {
          if (!bell) {
            return of(false);
          }

          const relatedBusinessItem =
            value && value.scheduleId ? value : scheduleBi;

          switch (bell.typeId) {
            case ChamberBellTypeId.Division:
              return this.getReadyToVoteAction(
                relatedBusinessItem.scheduleId,
                bell.id
              );
            case ChamberBellTypeId.Voting:
              return this.getEndVoteAction(relatedBusinessItem, bell);
            case ChamberBellTypeId.Assemble:
              return this.stopGairmBell(
                value?.scheduleId || scheduleBi?.scheduleId || scheduleId
              );
            default:
              return of(false);
          }
        })
      )
      .subscribe();
  }

  getGairmDialogRef(): Observable<boolean> {
    this.bellActionDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      ...this.dialogDefaultOptions,
      data: {
        title: 'DIALOG.RING_BELL.GAIRM.TITLE',
        message: 'DIALOG.RING_BELL.DESCRIPTION',
        cancelButton: { name: 'DIALOG.CANCEL.BUTTON' },
        confirmButton: { name: 'DIALOG.YES.BUTTON' }
      }
    });
    return this.bellActionDialogRef
      .afterClosed()
      .pipe(tap(() => this.clearBellDialogRef()));
  }

  getQuorumDialogRef(): Observable<QuorumConfirmDialogData> {
    this.bellActionDialogRef = this.dialog.open(CallQuorumBellDialogComponent, {
      ...this.dialogDefaultOptions,
      data: {
        title: 'CALL_QUORUM_DIALOG.TITLE',
        message: 'CALL_QUORUM_DIALOG.LABEL',
        cancelButton: { name: 'COMMON.CANCEL' },
        confirmButton: { name: 'COMMON.START' },
        context: { minDuration: MIN_QUORUM_DURATION }
      }
    });

    return this.bellActionDialogRef
      .afterClosed()
      .pipe(tap(() => this.clearBellDialogRef()));
  }

  getCancelDivisionDialog(confirmLabel: string): Observable<string> {
    this.bellActionDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      ...this.dialogDefaultOptions,
      data: {
        title: 'START_BI_AND_CANCEL_DIV_MODAL.TITLE',
        message: 'START_BI_AND_CANCEL_DIV_MODAL.MESSAGE',
        cancelButton: { name: 'DIALOG.CANCEL.BUTTON' },
        confirmButton: { name: confirmLabel }
      }
    });
    return this.bellActionDialogRef.afterClosed().pipe(
      tap(() => this.clearBellDialogRef()),
      map(data => (data ? confirmLabel : null))
    );
  }

  getCancelQuorumDialog(
    title: string,
    message: string,
    messageParams: IBusinessItem[]
  ): MatDialogRef<CancelScheduleDivDialogComponent, any> {
    return this.dialog.open(CancelScheduleDivDialogComponent, {
      ...this.dialogDefaultOptions,
      data: { title, message, messageParams }
    });
  }

  getQuorumEndingDialog(
    title: string,
    message: string,
    messageParams
  ): MatDialogRef<QuorumEndingDialogComponent> {
    this.bellActionDialogRef = this.dialog.open(QuorumEndingDialogComponent, {
      ...this.dialogDefaultOptions,
      disableClose: true,
      data: { title, message, messageParams }
    });

    return this.bellActionDialogRef;
  }

  getExtendBellQuorumDialogRef(
    title: string,
    message: string
  ): MatDialogRef<ExtendBellDialogComponent, any> {
    return this.dialog.open(ExtendBellDialogComponent, {
      ...this.dialogDefaultOptions,
      data: { title, message }
    });
  }

  getResumeBusinessItemDialog(isGairmBell: boolean): Observable<string> {
    const title = isGairmBell
      ? 'RESUME_SITTING_GAIRM_MODAL.TITLE'
      : 'RESUME_BI_QUORUM_MODAL.TITLE';
    const message = isGairmBell
      ? 'RESUME_SITTING_GAIRM_MODAL.MESSAGE'
      : 'RESUME_BI_QUORUM_MODAL.MESSAGE';

    this.bellActionDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      ...this.dialogDefaultOptions,
      data: {
        title,
        message,
        cancelButton: { name: 'DIALOG.CANCEL.BUTTON' },
        confirmButton: { name: 'DIALOG.YES.BUTTON' }
      }
    });

    return this.bellActionDialogRef
      .afterClosed()
      .pipe(tap(() => this.clearBellDialogRef()));
  }

  private getCancelDialogRef(
    businessItem: ILiveScheduleBI,
    nextBi?: IBusinessItem | ILiveScheduleBI,
    isScheduleOrGuillotineDiv?: boolean,
    isQuorumSchedule?: boolean
  ): Observable<any> {
    const confirmLabel = isScheduleOrGuillotineDiv
      ? 'COMMON.CONFIRM'
      : 'DIALOG.YES.BUTTON';

    if (isScheduleOrGuillotineDiv || isQuorumSchedule) {
      const title = isQuorumSchedule
        ? 'CANCEL_SCHEDULE_QUORUM_MODAL.TITLE'
        : 'CANCEL_SCHEDULE_DIVISION_MODAL.TITLE';
      const message = isQuorumSchedule
        ? 'CANCEL_SCHEDULE_QUORUM_MODAL.MESSAGE'
        : 'CANCEL_SCHEDULE_DIVISION_MODAL.MESSAGE';
      this.bellActionDialogRef = this.dialog.open(
        CancelScheduleDivDialogComponent,
        {
          ...this.dialogDefaultOptions,
          data: {
            title,
            message,
            cancelButton: { name: 'DIALOG.CANCEL.BUTTON' },
            confirmButton: { name: confirmLabel },
            messageParams: [businessItem, nextBi]
          }
        }
      );
    } else {
      this.bellActionDialogRef = this.dialog.open(ConfirmationDialogComponent, {
        ...this.dialogDefaultOptions,
        data: {
          title: 'CANCEL_DIVISION_MODAL.TITLE',
          message: 'CANCEL_DIVISION_MODAL.MESSAGE',
          cancelButton: { name: 'DIALOG.CANCEL.BUTTON' },
          confirmButton: { name: confirmLabel }
        }
      });
    }

    return this.bellActionDialogRef
      .afterClosed()
      .pipe(tap(() => this.clearBellDialogRef()));
  }

  private getReadyToVoteAction(
    scheduleId: number,
    chamberBellId: number
  ): Observable<number> {
    this.bellActionDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      ...this.dialogDefaultOptions,
      data: {
        title: 'VOTE_ACTION_MODAL.START_VOTE.TITLE',
        message: 'VOTE_ACTION_MODAL.START_VOTE.MESSAGE',
        cancelButton: { name: 'DIALOG.CANCEL.BUTTON' },
        confirmButton: { name: 'DIALOG.YES.BUTTON' }
      }
    });

    return this.bellActionDialogRef.afterClosed().pipe(
      tap(() => this.clearBellDialogRef()),
      filter(data => data),
      take(1),
      switchMap(() =>
        this.bellService.startVoting({ scheduleId, chamberBellId })
      )
    );
  }

  private getEndVoteAction(
    scheduleBi: ILiveScheduleBI,
    bell: ChamberBell
  ): Observable<any> {
    const isBusinessItemBell =
      bell.subTypeId === ChamberBellSubTypeId.VotingBusinessItem;
    const title = isBusinessItemBell
      ? 'END_VOTE_MODAL.TITLE'
      : 'VOTE_OVER_MODAL.TITLE';
    const message = isBusinessItemBell
      ? 'END_VOTE_MODAL.MESSAGE'
      : 'VOTE_OVER_MODAL.MESSAGE';
    const nextBusinessItem$: Observable<
      IBusinessItem | ILiveScheduleBI | null
    > = isBusinessItemBell
      ? of(null)
      : this.store.select(selectReadyToStartBusinessItem);

    return nextBusinessItem$.pipe(
      switchMap(nextBusinessItem => {
        this.bellActionDialogRef = this.dialog.open(
          CancelScheduleDivDialogComponent,
          {
            ...this.dialogDefaultOptions,
            data: {
              title,
              message,
              messageParams: isBusinessItemBell
                ? null
                : [
                    scheduleBi,
                    nextBusinessItem.typeId !== BusinessItemTypeId.Adjournment
                      ? nextBusinessItem
                      : null
                  ],
              context: { dialogName: 'voteOverDialog' },
              confirmButton: { name: 'DIALOG.YES.BUTTON' }
            }
          }
        );
        return this.bellActionDialogRef.afterClosed();
      }),
      switchMap(
        (data: {
          selectedBusinessItem?: IBusinessItem | ILiveScheduleBI;
          duration?: string;
        }) => {
          this.clearBellDialogRef();
          if (!data) {
            return of(false);
          }

          let endVoteAction = StopBellActionId.EndVote;

          if (data.selectedBusinessItem) {
            const businessItemId =
              data.selectedBusinessItem.id ||
              (<ILiveScheduleBI>data.selectedBusinessItem).businessItemId;
            endVoteAction =
              businessItemId === scheduleBi.businessItemId
                ? StopBellActionId.EndVoteResume
                : StopBellActionId.EndVotePlayNext;
          }

          return this.bellService.stopBell(
            scheduleBi.scheduleId,
            endVoteAction
          );
        }
      ),
      take(1)
    );
  }

  private cancelBell(
    value: {
      businessItem: ILiveScheduleBI;
      option: MoreOption;
    },
    chamberBell: ChamberBell,
    scheduleId?: number
  ): void {
    const isScheduleOrGuillotineDiv =
      chamberBell &&
      [
        ChamberBellSubTypeId.DivisionGuillotine,
        ChamberBellSubTypeId.DivisionSchedule,
        ChamberBellSubTypeId.QuorumSchedule,
        ChamberBellSubTypeId.VotingGuillotine,
        ChamberBellSubTypeId.VotingSchedule
      ].includes(chamberBell.subTypeId);
    const isQuorumBusinessItem =
      chamberBell?.subTypeId === ChamberBellSubTypeId.QuorumBusinessItem;
    const isQuorumSchedule =
      chamberBell?.subTypeId === ChamberBellSubTypeId.QuorumSchedule;
    const hasValidStopAction =
      chamberBell &&
      (chamberBell?.validStopActions?.includes(
        StopBellActionId.CancelPlayNext
      ) ||
        chamberBell?.validStopActions?.includes(
          StopBellActionId.EndVotePlayNext
        ));
    let nextBusinessItem$: Observable<IBusinessItem | ILiveScheduleBI | null> =
      of(null);

    if (isScheduleOrGuillotineDiv && hasValidStopAction) {
      nextBusinessItem$ = this.store.select(selectReadyToStartBusinessItem);
    }

    nextBusinessItem$
      .pipe(
        take(1),
        switchMap(nextBi => {
          if (isQuorumBusinessItem) {
            return this.getCancelQuorumBusinessItemDialog();
          }

          return this.getCancelDialogRef(
            value.businessItem,
            nextBi?.typeId !== BusinessItemTypeId.Adjournment ? nextBi : null,
            isScheduleOrGuillotineDiv,
            isQuorumSchedule
          );
        }),
        take(1),
        filter(confirmed => confirmed && confirmed !== 'DIALOG.CANCEL.BUTTON'),
        switchMap(
          (data: {
            duration?: string;
            selectedBusinessItem?: IBusinessItem;
          }) => {
            if (isQuorumSchedule) {
              this.store.dispatch(BellTimerActions.stopBellTimer());
            }

            return this.getConditionalStopBell(
              scheduleId,
              isScheduleOrGuillotineDiv,
              value && value.businessItem,
              data.selectedBusinessItem
            );
          }
        )
      )
      .subscribe();
  }

  private getConditionalStopBell(
    scheduleId: number,
    isScheduleOrGuillotineDiv: boolean,
    businessItem: ILiveScheduleBI,
    nextBusinessItem?: IBusinessItem
  ): Observable<IBellTypes> {
    return iif(
      () => isScheduleOrGuillotineDiv,
      this.bellService.stopBell(
        businessItem.scheduleId || scheduleId,
        nextBusinessItem && nextBusinessItem.id === businessItem.id
          ? StopBellActionId.CancelResume
          : StopBellActionId.CancelPlayNext
      ),
      this.bellService.stopBell(
        businessItem.scheduleId || scheduleId,
        StopBellActionId.Cancel
      )
    );
  }

  private getCancelQuorumBusinessItemDialog(): Observable<string> {
    this.bellActionDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      ...this.dialogDefaultOptions,
      data: {
        title: 'STOP_BI_QUORUM_MODAL.TITLE',
        message: 'STOP_BI_QUORUM_MODAL.MESSAGE',
        cancelButton: { name: 'DIALOG.CANCEL.BUTTON' },
        confirmButton: { name: 'DIALOG.YES.BUTTON' }
      }
    });

    return this.bellActionDialogRef
      .afterClosed()
      .pipe(tap(() => this.clearBellDialogRef()));
  }

  private stopGairmBell(scheduleId: number): Observable<IBellTypes> {
    this.bellActionDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      ...this.dialogDefaultOptions,
      data: {
        title: 'STOP_GAIRM_MODAL.TITLE',
        message: 'STOP_GAIRM_MODAL.MESSAGE',
        cancelButton: { name: 'DIALOG.CANCEL.BUTTON' },
        confirmButton: { name: 'DIALOG.YES.BUTTON' }
      }
    });

    return this.bellActionDialogRef.afterClosed().pipe(
      tap(() => this.clearBellDialogRef()),
      filter(result => result),
      switchMap(() =>
        this.bellService.stopBell(scheduleId, StopBellActionId.Cancel)
      )
    );
  }

  private clearBellDialogRef() {
    this.bellActionDialogRef = null;
  }

  private handleBellChanges(): void {
    this.store
      .select(selectChamberBell)
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        if (this.bellActionDialogRef) {
          this.bellActionDialogRef.close();
          this.bellActionDialogRef = null;
        }
      });
  }
}
