import { Injectable } from '@angular/core';
import { Observable, of, shareReplay } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  OirHttpService,
  ToastNotificationsService
} from 'proceduralsystem-clientcomponents';
import { InAppState } from '@app/app.types';
import {
  IChatMessage,
  IChatResponse
} from '@shared/components/chat/chat.types';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  constructor(
    private oirHttp: OirHttpService,
    private store: Store<InAppState>,
    private toastService: ToastNotificationsService,
    private translate: TranslateService
  ) {}

  fetchChatData(): Observable<IChatResponse> {
    return this.oirHttp.get<IChatResponse>('/api/chat').pipe(shareReplay(1));
  }

  fetchQuickReplies(): Observable<string[]> {
    return of(this.translate.instant('SCHEDULE.CHAT.QUICK_REPLIES'));
  }

  sendMessage(message: IChatMessage): Observable<boolean> {
    return this.oirHttp
      .post<boolean>({
        path: '/api/chat',
        body: {
          ...message,
          isUrgent: message.isUrgent || false
        }
      })
      .pipe(
        tap(result => {
          if (!result) {
            this.toastService.addNotification({
              title: 'COMMON.REQUESTERROR',
              error: 'COMMON.REQUESTERRORDESCRIPTION'
            });
          }
        })
      );
  }

  acknowledgeMessage(id: number): Observable<boolean> {
    return this.oirHttp.put<boolean>({ path: '/api/chat', body: { id } }).pipe(
      tap(result => {
        if (!result) {
          this.toastService.addNotification({
            title: 'COMMON.REQUESTERROR',
            error: 'COMMON.REQUESTERRORDESCRIPTION'
          });
        }
      })
    );
  }
}
