import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on
} from '@ngrx/store';
import * as InChamberAction from '../actions/in-chamber.actions';
import { IBusinessItem } from '@app/pages/schedule/schedule.types';
import {
  ILiveScheduleBI,
  HubActionId
} from 'proceduralsystem-cbs-common-components';
import { ISpeakerParams } from '@app/app.types';

export const inChamberFeatureKey = 'in-chamber';

export interface InChamberState {
  chairDueToStart: string[];
  clerkDueToStart: string[];
  recalledSpeakersListId: number;
  recalledNotesId: number;
  params: ISpeakerParams;
  clockParams: ISpeakerParams;
  readyToStartBusinessItem: IBusinessItem | ILiveScheduleBI;
  changedSLBusinessItem: {
    businessItemId: number;
    status: number;
  } | null;
  changedClockItem: {
    businessItemId: number;
    status: number;
  };
  changedCliffNote: {
    businessItemId: number;
    status: number;
    actionId: HubActionId;
  };
}

export const selectInChamberState =
  createFeatureSelector<InChamberState>(inChamberFeatureKey);
export const selectChairDueToStart = createSelector(
  selectInChamberState,
  (state: InChamberState) => state.chairDueToStart
);
export const selectClerkDueToStart = createSelector(
  selectInChamberState,
  (state: InChamberState) => state.clerkDueToStart
);
export const selectRecalledSpeakersListId = createSelector(
  selectInChamberState,
  (state: InChamberState) => state.recalledSpeakersListId
);
export const selectRecalledNotesId = createSelector(
  selectInChamberState,
  (state: InChamberState) => state.recalledNotesId
);
export const selectSpeakerListSlotParams = createSelector(
  selectInChamberState,
  (state: InChamberState) => state.params
);
export const selectClockSlotSpeakerParams = createSelector(
  selectInChamberState,
  (state: InChamberState) => state.clockParams
);
export const selectReadyToStartBusinessItem = createSelector(
  selectInChamberState,
  (state: InChamberState) => state.readyToStartBusinessItem
);
export const selectChangedSLBusinessItem = createSelector(
  selectInChamberState,
  (state: InChamberState) => state.changedSLBusinessItem
);
export const selectClockSlotItem = createSelector(
  selectInChamberState,
  (state: InChamberState) => state.changedClockItem
);
export const selectCliffNoteItem = createSelector(
  selectInChamberState,
  (state: InChamberState) => state.changedCliffNote
);

const initialState: InChamberState = {
  chairDueToStart: null,
  clerkDueToStart: null,
  recalledSpeakersListId: null,
  recalledNotesId: null,
  params: null,
  clockParams: null,
  readyToStartBusinessItem: null,
  changedSLBusinessItem: null,
  changedClockItem: null,
  changedCliffNote: null
};

export const inChamberReducer = createReducer(
  initialState,
  on(InChamberAction.chairDueToStart, (state, { chairDueToStart }) => ({
    ...state,
    chairDueToStart
  })),
  on(InChamberAction.clerkDueToStart, (state, { clerkDueToStart }) => ({
    ...state,
    clerkDueToStart
  })),
  on(InChamberAction.recalledSpeakersList, (state, { itemId }) => ({
    ...state,
    recalledSpeakersListId: itemId
  })),
  on(InChamberAction.recalledNotes, (state, { itemId }) => ({
    ...state,
    recalledNotesId: itemId
  })),
  on(InChamberAction.speakerListSlotStateChanged, (state, { params }) => ({
    ...state,
    params
  })),
  on(
    InChamberAction.clockSlotSpeakerStateChanged,
    (state, { clockParams }) => ({
      ...state,
      clockParams
    })
  ),
  on(
    InChamberAction.readyToStartBusinessItem,
    (state, readyToStartBusinessItem) => ({
      ...state,
      readyToStartBusinessItem
    })
  ),
  on(
    InChamberAction.speakersListStateChanged,
    (state, { changedSLBusinessItem }) => ({
      ...state,
      changedSLBusinessItem
    })
  ),
  on(InChamberAction.clockSlotStateChanged, (state, { changedClockItem }) => ({
    ...state,
    changedClockItem
  })),
  on(InChamberAction.cliffNoteStateChanged, (state, { changedCliffNote }) => ({
    ...state,
    changedCliffNote
  }))
);
