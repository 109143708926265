import { createReducer, on } from '@ngrx/store';
import * as BellTimerActions from '@app/store/actions/bell-timer.actions';

export const bellTimerFeatureKey = 'bell-timer';

export interface BellTimerState {
  startTime: Date | string | null;
  duration: number;
  currentTime: number | null;
}

export const initialBellTimerState: BellTimerState = {
  startTime: null,
  duration: 0,
  currentTime: null
};

export const bellTimerReducer = createReducer(
  initialBellTimerState,
  on(
    BellTimerActions.startBellTimer,
    (state, { startTime, durationPlanned }) => ({
      ...state,
      startTime,
      durationPlanned,
      currentTime: 0
    })
  ),
  on(BellTimerActions.updateBellTimer, (state, { currentTime }) => ({
    ...state,
    currentTime
  })),
  on(BellTimerActions.stopBellTimer, state => ({
    ...state,
    startTime: null,
    currentTime: null
  }))
);
