import { ChangeDetectionStrategy, Component } from '@angular/core';
import { selectChamberUser } from '@app/store/reducers/live-sharing.reducer';
import { UserRole } from 'proceduralsystem-cbs-common-components';
import { OirAuthService } from 'proceduralsystem-clientcomponents';
import { InCbsUserService } from '@app/services/in-cbs-user.service';
import { Store } from '@ngrx/store';
import { InAppState } from '@app/app.types';

@Component({
  selector: 'in-chamber-no-access-page',
  templateUrl: './in-chamber-no-access-page.component.html',
  styleUrls: ['./in-chamber-no-access-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InChamberNoAccessPageComponent {
  hasUserSelected = true;

  constructor(
    private authService: OirAuthService,
    private inCbsUserService: InCbsUserService,
    private store: Store<InAppState>
  ) {
    if (
      this.authService.hasSomeRoles([
        UserRole.CeannComhairle,
        UserRole.ChamberClerk
      ])
    ) {
      this.store.select(selectChamberUser).subscribe(user => {
        this.hasUserSelected = !!user;
        
        if (!user) {
          this.inCbsUserService.openUserClockInDialog();
        }
      });
    }
  }
}
