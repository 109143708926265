<div class="chat-container">
    <div class="chat-title-container sidebar-header">
        <h3 class="header-title ta_c_chatTitle">{{ 'CHAT.TITLE' | translate }}</h3>
        <mat-icon class="close-icon ta_c_close" mat-dialog-close aria-label="chat close icon">close</mat-icon>
    </div>
    <div #chatBody class="chat-body" (scroll)="onScroll()">
        <div *ngFor="let message of messages$ | async; let i = index" class="ta_c_chatMessageInstance_{{i}}">
            <oir-chat-message
                [message]="message"
                [userFullName]="userFullName">
            </oir-chat-message>
        </div>
    </div>
    <div class="chat-footer">
        <form [formGroup]="form">
            <mat-form-field class="chat-message-box" appearance="fill">
                <textarea matInput
                          formControlName="messageBox"
                          maxlength="{{ MESSAGE_MAX_LENGTH }}"
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="6"
                          class="body-secondary ta_c_chatInput"
                          [placeholder]="'CHAT.MESSAGE_BOX.PLACEHOLDER' | translate"></textarea>
            </mat-form-field>
        </form>
        <mat-chip-list aria-label="quick replies">
            <mat-chip class="chip ta_c_chatReplyOption_{{i}}" *ngFor="let reply of quickReplies$ | async; let i = index" (click)="onQuickReplies(reply)">
                {{ reply | translate }}
            </mat-chip>
        </mat-chip-list>
        <button mat-flat-button
                class="btn-primary-flat chat-send-button ta_c_chatSendButton"
                color="accent"
                (click)="onSend()"
                [disabled]="form.invalid || form.pristine">
                {{ 'CHAT.SEND_BUTTON' | translate }}
        </button>
    </div>
</div>
