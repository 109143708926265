import { Injectable } from '@angular/core';
import {
  AccessOperation,
  IChamberUser,
  UserRole
} from 'proceduralsystem-cbs-common-components';
import {
  ScheduleAccessOperation,
  ScheduleComponentAccessOperation
} from './schedule.types';

@Injectable({
  providedIn: 'root'
})
export class ScheduleAccessService {
  getAccessOperations(chamberUser: IChamberUser): ScheduleAccessOperation[] {
    return chamberUser
      ? chamberUser.role === UserRole.ChamberClerk
        ? [
            AccessOperation.ManageActionButtons,
            AccessOperation.Edit,
            ScheduleComponentAccessOperation.ShowSuspendScheduleButton,
            ScheduleComponentAccessOperation.ShowAdjournScheduleButton,
            ScheduleComponentAccessOperation.ShowExtendSuspendScheduleButton,
            ScheduleComponentAccessOperation.ShowResumeScheduleButton,
            ScheduleComponentAccessOperation.ShowRingBellButton
          ]
        : [AccessOperation.View]
      : [];
  }
}
