<div class="modal-dialog ta_s_{{dialogName}}">
  <div class="modal-header">
    <h2 mat-dialog-title>
      {{ data.title | translate }}
    </h2>
    <mat-icon [mat-dialog-close]="null" class="mat-close-icon" aria-label="close icon">
      close
    </mat-icon>
  </div>
  <mat-dialog-content class="modal-body">
    <div class="description">
      {{ data.message | translate }}
    </div>
    <form [formGroup]="scheduleForm">
      <mat-radio-group class="cancel-business-item-radio-group" formControlName="choice">
        <mat-radio-button
          *ngFor="let businessItem of businessItems; let i = index"
          class="radio-button-mat ta_s_{{dialogName}}_option_{{i}}"
          [value]="EndingBellTypes[EndingBellTypes[i + 1]]">
          <ng-container *ngIf="i === 0">{{'EXTEND_BELL_DIALOG.RESUME.LABEL' | translate}}: </ng-container>
          <ng-container *ngIf="i === 1">{{'COMMON.START' | translate}}: </ng-container>
          <span [innerHTML]="(businessItem.workItemTitle || businessItem.title || businessItem.businessItemTitle) | safehtml"></span>
        </mat-radio-button>
        <mat-radio-button
          *ngIf="(bellTypes.typeId === ChamberBellTypeId.Quorum) && bellTypes.subTypeId === ChamberBellSubTypeId.QuorumSchedule"
          class="radio-button-mat ta_s_{{dialogName}}_option_{{businessItems.length + 1}}"
          [value]="EndingBellTypes.Extend">
          {{'EXTEND_QUORUM_DIALOG.TITLE' | translate}}
        </mat-radio-button>
      </mat-radio-group>

      <mat-form-field
        *ngIf="(bellTypes.typeId === ChamberBellTypeId.Quorum) && (bellTypes.subTypeId === ChamberBellSubTypeId.QuorumSchedule)"
        appearance="fill"
        class="cancel-dialog-input-field ta_s_stopQuorumDialog_duration">
          <mat-label>{{ 'EXTEND_BELL_DIALOG.DURATION.LABEL' | translate }}</mat-label>
          <input
            matInput
            formControlName="duration"
            autoComplete="off"
            maxlength="2">
      </mat-form-field>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions class="dialog-buttons">
    <button mat-button class="btn-basic ta_cancel" color="primary" [mat-dialog-close]="null"
      type="button">
      {{ 'DIALOG.CANCEL.BUTTON' | translate | uppercase }}
    </button>
    <button
      mat-button class="btn-basic ta_confirm"
      color="primary"
      type="button"
      [disabled]="scheduleForm.invalid"
      [mat-dialog-close]="scheduleForm.getRawValue()">
      {{ 'COMMON.CONFIRM' | translate | uppercase }}
    </button>
  </mat-dialog-actions>
</div>
