<div class="active-user-container">
  <div class="sidebar-header">
      <h3 class="header-title ta_activeUsersTitle">{{ 'ACTIVE_USERS.TITLE' | translate }}</h3>
      <mat-icon class="close-icon ta_close" mat-dialog-close aria-label="close icon">close</mat-icon>
  </div>
  <div class="active-user-body">
    <ng-container *ngIf="(activeUsers$ | async); let activeUsers; else noItems">
      <ng-container *ngIf="activeUsers.length > 0; else noItems">
        <div *ngFor="let item of activeUsers; let i = index">
          <mat-divider *ngIf="i > 0"></mat-divider>
          <div class="active-user-instance ta_activeUser_{{i}}">
            <div class="title-container">
              <ng-container *ngIf="item.user.role === UserRole.CeannComhairle">
                <div class="user-icon ta_activeUserIcon"><div>CC</div></div>
                <label class="user-name ta_activeUserName">{{ 'CHAT.CHAIR.USER_NAME' | translate }}</label>
              </ng-container>
              <ng-container *ngIf="item.user.role !== UserRole.CeannComhairle">
                <div class="user-icon ta_activeUserIcon"><div>{{ BadgeUtils.getCredentials(item.user) }}</div></div>
                <label class="user-name ta_activeUserName">{{ item.user.name }}</label>
              </ng-container>
            </div>
            <div class="main-container">
              <oir-active-user-path-message [data]="item"></oir-active-user-path-message>          
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #noItems>
  <div class="no-items"><p>{{ 'ACTIVE_USERS.NO_ITEMS' | translate }}</p></div>
</ng-template>
