import { Injectable } from '@angular/core';
import {
  ChamberBellSubTypeId,
  ChamberBellTimeTypeId,
  IBellTypes,
  StopBellActionId
} from 'proceduralsystem-cbs-common-components';
import { BellOptionMenuItemsResp } from '@app/shared/components/bell-options-menu/bell-options-menu.types';
import {
  OirHttpService,
  OirDisplayError,
  ToastNotificationsService
} from 'proceduralsystem-clientcomponents';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

interface StartDivBellPayload {
  scheduleId: number;
  stageId: number;
  divisionDuration?: number;
  chamberBellSubTypeId: ChamberBellSubTypeId;
  chamberBellTimeTypeId: ChamberBellTimeTypeId;
}

interface StartQuorumBellPayload {
  scheduleId: number;
  quorumDuration?: number;
  chamberBellSubTypeId: ChamberBellSubTypeId;
}

interface StartGairmBellPayload {
  scheduleId: number;
  chamberBellSubTypeId: ChamberBellSubTypeId;
}

export interface Stage {
  name: string;
  id: number;
}

@Injectable({
  providedIn: 'root'
})
export class BellService {
  constructor(
    private readonly oirHttp: OirHttpService,
    private readonly toastService: ToastNotificationsService
  ) {}

  getRingBellOptions(scheduleId: number): Observable<BellOptionMenuItemsResp> {
    return this.oirHttp.get<BellOptionMenuItemsResp>(
      `/api/Bell/${scheduleId}/RingBellOptions`
    );
  }

  startDivisionBell(body: StartDivBellPayload): Observable<IBellTypes> {
    return this.oirHttp.post<IBellTypes>({
      path: `/api/Bell/${body.scheduleId}/Division/Start`,
      body
    });
  }

  startGairmBell(body: StartGairmBellPayload): Observable<IBellTypes> {
    return this.oirHttp.post<IBellTypes>({
      path: `/api/Bell/${body.scheduleId}/Gairm/Start`,
      body
    });
  }

  startQuorumBell(body: StartQuorumBellPayload): Observable<number> {
    return this.oirHttp
      .post<number>({
        path: `/api/Bell/${body.scheduleId}/Quorum/Start`,
        body
      })
      .pipe(catchError(error => this._generalErrorCase(error)));
  }

  extendQuorumBell(
    scheduleId: number,
    chamberBellId: number,
    quorumDuration: number
  ): Observable<number> {
    return this.oirHttp
      .put<number>({
        path: `/api/Bell/${scheduleId}/Quorum/Extend`,
        body: {
          scheduleId,
          chamberBellId,
          quorumDuration
        }
      })
      .pipe(catchError(error => this._generalErrorCase(error)));
  }

  stopBell(
    scheduleId: number,
    action: StopBellActionId
  ): Observable<IBellTypes> {
    return this.oirHttp.put<IBellTypes>({
      path: `/api/Bell/${scheduleId}/StopBell`,
      body: { scheduleId, action }
    });
  }

  cancelOptions(scheduleId: number, bellId: number): Observable<IBellTypes> {
    return this.oirHttp.get<IBellTypes>({
      path: `/api/Bell/${scheduleId}/CancelOptions/${bellId}`
    });
  }

  getDivisionStage(
    scheduleId: number
  ): Observable<{ businessItemId: number; stages: Stage[] }> {
    return this.oirHttp.get<{ businessItemId: number; stages: Stage[] }>(
      `/api/Bell/${scheduleId}/Division/Options/Stages`
    );
  }

  extendDivBell(
    scheduleId: number,
    chamberBellId: number,
    divisionBellDuration: number
  ): Observable<IBellTypes> {
    return this.oirHttp.put<IBellTypes>({
      path: `/api/Bell/${scheduleId}/Division/Extend`,
      body: { scheduleId, chamberBellId, divisionBellDuration }
    });
  }

  startVoting(body: {
    scheduleId: number;
    chamberBellId: number;
  }): Observable<number> {
    return this.oirHttp.post<number>({
      path: `/api/Bell/${body.scheduleId}/Division/StartVoting`,
      body
    });
  }

  private _generalErrorCase(error: OirDisplayError): Observable<number> {
    this.toastService.addNotification({
      title: 'COMMON.REQUESTERROR',
      error: error.detail
    });

    return of(-1);
  }
}
