<div class="app-container ta_app_container" [class.fullSized]="isNavPaneCollapsed">
  <div class="leftSide" [class.collapsed]="isNavPaneCollapsed">
    <oir-in-chamber-side-navigation class="ta_navigation_panel"
                                    [collapsed]="isNavPaneCollapsed"></oir-in-chamber-side-navigation>
    <div class="leftBottom">
      <div aria-hidden="true" class="icon ta_app_navigationToggle">
        <img src="./assets/img/toggle.svg" alt="node-icon" (click)="changeNavState()">
      </div>
      <div class="navigation-toggle-text" *ngIf="!isNavPaneCollapsed">{{ "COMMON.MINIMIZE" | translate }}</div>
    </div>
  </div>
  <div class="rightSide">
    <oir-header-bar class="pageHeader ta_page_header"></oir-header-bar>
    <oir-progress-bar *ngIf="accessOperations.length > 0"
                      [enableControls]="accessOperations.includes(AccessOperation.ManageActionButtons)"
                      (itemClick)="openItem($event)"
                      (bellActionClick)="scheduleDialogsService.onBellActionClick($event, scheduleId)"
                      (optionClick)="scheduleDialogsService.openMoreOptionDialog($event, chamberBell)"
                      (startBusinessItem)="onStartBusinessItem($event)"></oir-progress-bar>
    <div class="pageContent ta_page_content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <oir-chat-button *ngIf="isChatBtnVisible && accessOperations.length > 0"></oir-chat-button>
</div>
