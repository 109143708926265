import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'oir-switch-user-dialog',
  templateUrl: './switch-user.component.html',
  styleUrls: ['./switch-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwitchUserComponent {
  @Input() type: 'clock-in' | 'clock-out';
  form = new FormGroup({
    clerk: new FormControl()
  });
}
