import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IOirDialogData } from 'proceduralsystem-clientcomponents';

@Component({
  selector: 'oir-extend-bell-dialog',
  templateUrl: './extend-bell-dialog.component.html',
  styleUrls: ['./extend-bell-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExtendBellDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: IOirDialogData) {}

  duration = new FormControl(null, [
    Validators.min(1),
    Validators.max(99),
    Validators.pattern(/^\d+$/),
    Validators.required
  ]);
}
