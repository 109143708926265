import { ScheduleStatusId } from 'proceduralsystem-cbs-common-components';

export enum ChatButtonPosition {
  left = 'left',
  right = 'right'
}

export const ScheduleChatStatuses = [
  ScheduleStatusId.InProgress,
  ScheduleStatusId.Suspended
];
