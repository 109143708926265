import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ChangeDetectorRef
} from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
  ApplicationEntry,
  DialogPosition,
  OirDialogService,
  EnvironmentColourEnum,
  ConfigurationService
} from 'proceduralsystem-clientcomponents';
import {
  TabSessionStorageName,
  SubTabSessionStorageName,
  SessionStorageUtils,
  AppConfig
} from 'proceduralsystem-cbs-common-components';
import { InChamberSideNavigationService } from './in-chamber-side-navigation.service';

@Component({
  selector: 'oir-in-chamber-side-navigation',
  templateUrl: './in-chamber-side-navigation.component.html',
  styleUrls: ['./in-chamber-side-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InChamberSideNavigationComponent {
  @Input() collapsed = false;
  otherApps$: Observable<ApplicationEntry[]> =
    this.navigationService.fetchUserApplications();
  applicationSelectExpanded = false;

  constructor(
    public readonly navigationService: InChamberSideNavigationService,
    private readonly configuration: ConfigurationService<AppConfig>,
    private readonly translate: TranslateService,
    private readonly oirDialogService: OirDialogService,
    private readonly cd: ChangeDetectorRef
  ) {}

  navigate(url: string): void {
    window.location.href = url;
  }

  toggleApplicationSelect(): void {
    this.applicationSelectExpanded = !this.applicationSelectExpanded;
    this.cd.detectChanges();
  }

  switchApplication(app: ApplicationEntry): void {
    const confirmButton = 'SWITCHAPP.BUTTON.SWITCH';

    this.oirDialogService
      .open({
        title: 'SWITCHAPP.DIALOG.TITLE',
        message: this.translate.instant('SWITCHAPP.DIALOG.MESSAGE', [
          app.title
        ]),
        cancelButton: { name: 'COMMON.CANCEL' },
        confirmButton: { name: confirmButton },
        position: DialogPosition.CENTER
      })
      .subscribe(res => {
        this.toggleApplicationSelect();
        if (res === confirmButton) {
          this.navigate(app.path);
        }
      });
  }

  clearSessionTabs(): void {
    SessionStorageUtils.saveUserData(0, TabSessionStorageName);
    SessionStorageUtils.saveUserData(0, SubTabSessionStorageName);
  }

  getEnvironmentColour(): string {
    const endpoint = this.configuration.getValue('CommsEndpoint');
    const env = endpoint ? endpoint.url : '';
    let environmentColour: string;
    switch (true) {
      case env.includes('dev'):
        environmentColour = EnvironmentColourEnum.Development;
        break;
      case env.includes('localhost'):
        environmentColour = EnvironmentColourEnum.Development;
        break;
      case env.includes('systemtest'):
        environmentColour = EnvironmentColourEnum.SystemTest;
        break;
      case env.includes('bpt'):
        environmentColour = EnvironmentColourEnum.Bpt;
        break;
      case env.includes('prelive'):
        environmentColour = EnvironmentColourEnum.Prelive;
        break;
      default:
        environmentColour = EnvironmentColourEnum.Default;
    }

    return environmentColour;
  }
}
