import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => {
  const promise = platformBrowserDynamic()
    .bootstrapModule(AppModule);
  promise.catch(err => {
    console.error('Chamber Business Platform application bootstrap error', err);
  });
};

bootstrap();
