import { Component, OnInit } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from '@angular/material-moment-adapter';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  ConfigurationService,
  MonitoringService,
  OirAuthService
} from 'proceduralsystem-clientcomponents';
import {
  LanguageType,
  CookieItem,
  CONNECTION_HUB_SERVICE_TOKEN,
  CbsNavigationService,
  UserRole,
  AppConfig
} from 'proceduralsystem-cbs-common-components';
import { InCbsNavigationService } from './services/in-cbs-navigation.service';
import { InConnectionHubService } from './services/in-connection-hub.service';
import { InCbsUserService } from '@app/services/in-cbs-user.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: CONNECTION_HUB_SERVICE_TOKEN,
      useExisting: InConnectionHubService
    },
    {
      provide: CbsNavigationService,
      useExisting: InCbsNavigationService
    }
  ]
})
export class AppComponent implements OnInit {
  constructor(
    private readonly config: ConfigurationService<AppConfig>,
    private readonly translate: TranslateService,
    private readonly navigationService: InCbsNavigationService,
    private readonly monitoringService: MonitoringService,
    private readonly authService: OirAuthService,
    private readonly inCbsUserService: InCbsUserService,
    private readonly connectionHubService: InConnectionHubService,
    private readonly adapter: DateAdapter<Date>
  ) {
    this.translate.addLangs([LanguageType.en, LanguageType.ga]);
    this.translate.getTranslation(LanguageType.ga); // This is required when two languages need to be translated at the same time.
    this.translate.setDefaultLang(LanguageType.ga);
  }

  ngOnInit(): void {
    this.showMessage('CBM In-Chamber application started');

    forkJoin([
      this.config.get('VersionNumber'),
      this.config.get('ModuleName'),
      this.config.get('AppInsightsKey'),
      this.config.get('defaultLang')
    ])
      .pipe(take(1))
      .subscribe(([version, moduleName, appInsightsKey, defaultLang]) => {
        const cookieItem = this.config.getCookie(CookieItem.language);
        const cookieLang =
          cookieItem === LanguageType.en || cookieItem === LanguageType.ga
            ? cookieItem
            : defaultLang;

        this.showMessage(`v. ${version} with '${cookieLang}' language`);

        if (
          this.authService.hasSomeRoles([
            UserRole.CeannComhairle,
            UserRole.ChamberClerk
          ])
        ) {
          this.connectionHubService.init();
          this.inCbsUserService.checkSession().subscribe();
        }

        this.translate.use(cookieLang);
        this.adapter.setLocale(cookieLang);

        this.navigationService.initNavigation(moduleName);
        this.monitoringService.init(
          appInsightsKey,
          this.authService.getUsername()
        );
      });
  }

  private showMessage(message: string): void {
    console.log(
      `%c${message}`,
      'color: #b39841; font-family: Arial; font-size: 1.5em; font-weight: bold;'
    );
  }
}
