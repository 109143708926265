import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActiveUsersComponent } from './active-users.component';
import { ProceduralsystemClientcomponentsModule } from 'proceduralsystem-clientcomponents';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { ActiveUserPathMessageComponent } from './components/active-user-path-message/active-user-path-message.component';

@NgModule({
  declarations: [ActiveUsersComponent, ActiveUserPathMessageComponent],
  imports: [
    CommonModule,
    ProceduralsystemClientcomponentsModule,
    MatIconModule,
    MatDialogModule,
    TranslateModule,
    MatDividerModule
  ],
  exports: [ActiveUsersComponent]
})
export class ActiveUsersModule {}
